<template>
  <div class="finaltesting">
    <div class="finaltesting__content">
      <div class="finaltesting__content-title main-title blocks-title">
        <h1 class="finaltesting__content-title-txt">{{ $t('tests.finalTest') }}</h1>
        <div class="finaltesting__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('tests.finalTest') }}</p>
        </div>
      </div>

      <div class="finaltesting__content_test blocks" v-if="finalTestData && !isSubmittingFinalTest">
        <div class="finaltesting__content_test-back">
          <button @click="$router.go(-1)">
            <img src="@/assets/icons/back-btn.svg" alt="back-btn">
            <p class="main-title">{{ $t('buttons.back') }}</p>
          </button>
        </div>
        <h2>{{ finalTestData.title }}</h2>
        <div
            class="finaltesting__content_test-questions"
            v-for="(question, index) in finalTestData.questions"
            :key="question.id"
            v-show="currentPageIndex === index"
        >
          <h2 class="main-title">{{ index + 1 }}. {{ question.title }}</h2>

          <div class="finaltesting__content_test-questions-answers">
            <div
                class="finaltesting__content_test-questions-answers-btn"
                v-for="(answer, idx) in question.answers"
                :key="answer.id"
            >
              <button
                  :class="{ 'selected': userFinalTestAnswers[question.id]?.includes(answer.id)}"
                  @click="selectFinalTestAnswer(question.id, answer.id)"
              >
                {{ idx + 1 }}. {{ answer.title }}
              </button>
            </div>
          </div>
        </div>

        <div class="finaltesting__content_test-navigation">
          <div class="finaltesting__content_test-navigation-prev">
            <button
                @click="prevQuestion"
                :disabled="currentPageIndex === 0"
                v-show="currentPageIndex > 0"
                class="finaltesting__content_test-navigation-prev-btn"
            >
              <img src="@/assets/icons/arrow-left.svg" alt="arrow-left">
              <p class="main-title">{{ $t('buttons.previous') }}</p>
            </button>
          </div>
          <div class="finaltesting__content_test-navigation-submit">
            <button
                class="finaltesting__content_test-navigation-submit-btn main-button"
                @click="submitFinalTest"
                v-if="isLastPage"
            >
              {{ $t('buttons.submit') }}
            </button>
          </div>
          <div class="finaltesting__content_test-navigation-next">
            <button
                @click="nextQuestion"
                :disabled="!userFinalTestAnswers[finalTestData.questions[currentPageIndex]?.id]"
                v-show="!isLastPage"
                class="finaltesting__content_test-navigation-next-btn"
            >
              <p class="main-title">{{ $t('buttons.next') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
        </div>
      </div>
      <div class="finaltesting__content_test-result blocks" v-if="finalTestResult && isSubmittingFinalTest">
        <div class="finaltesting__content_test-result-info">
          <h2 class="semi-bold-title">{{ finalTestData.title }}</h2>
          <p class="main-title">{{ $t('tests.youScored') }} {{ finalTestResult.score }} {{ $t('tests.gradesScore') }}</p>
          <p class="main-title">{{ $t('tests.correctAnswersCount') }} {{ finalTestResult.is_correct_count }}</p>
          <p class="main-title">{{ $t('tests.incorrectAnswersCount') }} {{ finalTestResult.is_incorrect_count }}</p>
          <p class="main-title" v-if="testSubmitMessage">{{ testSubmitMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  components: {
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isTestFinished()) {
      this.$toaster.error(this.$t('errors.userTestLeave'));
      next(false);
    } else {
      next();
    }
  },
  data() {
    return {
      userFinalTestAnswers: {},
      finalTestData: null,
      currentPageIndex: 0,
      isSubmittingFinalTest: false,
      finalTestResult: [],
      testSubmitMessage: "",
    };
  },
  computed: {
    isLastPage() {
      return this.currentPageIndex === this.finalTestData.questions.length - 1;
    },
  },
  methods: {
    selectFinalTestAnswer(questionId, answerId) {
      if (!questionId || !answerId || !this.userFinalTestAnswers) {
        this.$toaster.error(this.$t('errors.userNotAnswers'));
        return;
      }

      if (!this.userFinalTestAnswers[questionId]) {
        this.userFinalTestAnswers[questionId] = [];
      }

      const answerIndex = this.userFinalTestAnswers[questionId].indexOf(answerId);

      if (answerIndex === -1) {
        // Если ответ еще не выбран, добавить его в массив
        this.userFinalTestAnswers[questionId].push(answerId);
      } else {
        // Если ответ уже выбран, удалить его из массива
        this.userFinalTestAnswers[questionId].splice(answerIndex, 1);
      }
      this.saveFinalTestAnswersToLocalStorage();
    },
    isTestFinished() {
      return this.isLastPage && this.isSubmittingFinalTest;
    },
    nextQuestion() {
      if (!this.userFinalTestAnswers[this.finalTestData.questions[this.currentPageIndex]?.id] ||
          this.userFinalTestAnswers[this.finalTestData.questions[this.currentPageIndex]?.id].length === 0) {
        this.$toaster.error(this.$t('errors.userNotAnswers'));
        return;
      }
      if (this.currentPageIndex < this.finalTestData.questions.length - 1) {
        this.currentPageIndex++;
        this.saveCurrentPageToLocalStorage();
      }
    },
    prevQuestion() {
      if (this.currentPageIndex > 0) {
        this.currentPageIndex--;
      }
      this.saveCurrentPageToLocalStorage();
    },
    submitFinalTest() {
      const educationId = this.$route.params.educationId;
      const finalTestId = this.$route.params.finalTestId;
      this.clearFinalLocalStorage();
      const answers = [];
      this.isSubmittingFinalTest = true;
      for (const questionId in this.userFinalTestAnswers) {
        if (this.userFinalTestAnswers.hasOwnProperty(questionId)) {
          const answerId = this.userFinalTestAnswers[questionId];
          answers.push({ question_id: questionId, answer_id: answerId });
        }
      }
      const postData = {
        answers: answers,
      };
      this.$axios
          .post(`courses/${educationId}/courseTests/${finalTestId}/finished`, postData)
          .then((response) => {
            this.finalTestResult = response.data.courseTestResult;
            this.testSubmitMessage = response.data.message;
            if (this.finalTestResult && this.finalTestResult.score < 70) {
              this.$toaster.error(response.data.message);
            }
            this.isSubmittingFinalTest = true;
          })
          .catch((error) => {
            this.$toaster.error(error.response.message);
            this.isSubmittingFinalTest = true;
          });
    },
    saveFinalTestAnswersToLocalStorage() {
      localStorage.setItem('userFinalTestAnswers', JSON.stringify(this.userFinalTestAnswers));
    },

    saveCurrentPageToLocalStorage() {
      localStorage.setItem('currentPageIndex', this.currentPageIndex.toString());
    },

    clearFinalLocalStorage() {
      localStorage.removeItem('userFinalTestAnswers');
      localStorage.removeItem('currentPageIndex');
    },

    loadFinalTestAnswersFromLocalStorage() {
      const storedAnswers = localStorage.getItem('userFinalTestAnswers');
      if (storedAnswers) {
        this.userFinalTestAnswers = JSON.parse(storedAnswers);
      }
    },

    loadCurrentPageFromLocalStorage() {
      const storedPage = localStorage.getItem('currentPage');
      if (storedPage) {
        this.currentPageIndex = parseInt(storedPage, 10);
      }
    },
  },
  created() {
    this.loadFinalTestAnswersFromLocalStorage();
    this.loadCurrentPageFromLocalStorage();
    this.finalTestData = store.state.finalTestData;
  },
};
</script>

<style lang="scss" scoped>
.finaltesting {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_test {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      padding: 40px;
      &-back {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        button {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      h2 {
        text-align: center;
      }
      &-questions {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px;
        gap: 80px;
        background: #F9F9F9;
        border-radius: 10px;
        h2 {
          font-size: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 0.6684)), 30px);
        }
        &-answers {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          width: 100%;
          &-btn {
            flex-basis: calc(50% - 20px);
            button {
              width: 100%;
              background-color: #02AEF080;
              padding: 20px 40px;
              border-radius: 10px;
              height: 100%;
            }
            @media screen and (max-width: $tablet) {
              flex-basis: 100%;
            }
          }
          @media screen and (max-width: $tablet) {
            width: auto;
          }
        }
      }
      &-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        width: 100%;
        &-prev {
          display: flex;
          width: 33%;
          align-items: start;
          justify-content: start;
          &-btn {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        &-next {
          display: flex;
          width: 33%;
          align-items: end;
          justify-content: end;
          &-btn {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        &-submit {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33%;
        }
      }
      &-result {
        padding: 40px;
        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 80px 0;
          gap: 20px;
          background: #F9F9F9;
          border-radius: 10px;
          h2 {
            font-size: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 0.6684)), 30px);
          }
          p {
            font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
          }
        }
      }
    }
  }
  .selected {
    background-color: #02AEF0;
    color: #fff;
  }
}
</style>
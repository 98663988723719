<template>
  <div class="education">
    <div class="education__content">
      <div class="education__content_course">
        <EducationMainBlock
            class="education__content_course-block"
            :modules="modules"
            :lastlesson="lastAvailableLessonId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EducationMainBlock from "@/components/education/EducationMainBlock.vue";

export default {
  components: {
    EducationMainBlock
  },
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.education {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
h2 {
  font-size: 30px;
  padding-top: 40px;
}
</style>
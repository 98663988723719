<template>
<div class="navigation">
  <div
      class="navigation_dashboard-nav blocks"
      v-if="getUser"
  >
    <router-link
        to="/personal"
        class="navigation_dashboard-nav-pages"
        :class="{ 'active': $route.path === '/personal' }"
    >
      <img src="../../assets/icons/accprofile.svg" alt="accprofile">
      <p class="second-title">{{ $t('profile') }}</p>
    </router-link>
    <router-link
        to="/personal/courses-list"
        class="navigation_dashboard-nav-pages"
        :class="{ 'active': $route.path === '/personal/courses-list' }"
    >
      <img src="../../assets/icons/list.svg" alt="list">
      <p class="second-title">{{ $t('personal.listCourses') }}</p>
    </router-link>
    <router-link
        to="/personal/calendar"
        class="navigation_dashboard-nav-pages"
        :class="{ 'active': $route.path === '/personal/calendar' }"
    >
      <img src="../../assets/icons/calendar.svg" alt="calendar">
      <p class="second-title">{{ $t('calendar') }}</p>
    </router-link>
    <router-link
        to="/personal/mycourses"
        class="navigation_dashboard-nav-pages"
        :class="{ 'active': $route.path.startsWith('/personal/mycourses') }"
    >
      <img src="../../assets/icons/mycourses.svg" alt="mycourses">
      <p class="second-title">{{ $t('personal.myCourses') }}</p>
    </router-link>
<!--    <router-link-->
<!--        to="/personal/analytics"-->
<!--        class="navigation_dashboard-nav-pages"-->
<!--        :class="{ 'active': $route.path === '/personal/analytics' }"-->
<!--    >-->
<!--      <img src="../../assets/icons/analytics.svg" alt="analytics">-->
<!--      <p class="second-title">Аналитика</p>-->
<!--    </router-link>-->
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["getLang", "getUser", "getAuth", "getUserCourses"]),
  },
  methods: {
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.navigation_dashboard {
  display: flex;
  flex-direction: column;
  min-width: 25%;
  gap: 20px;
  padding: 30px 20px 30px 20px;
  &-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 10px;
    &-pages {
      display: flex;
      align-items: center;
      padding: 20px 32px 20px 32px;
      gap: 20px;
      img {
        width: 30px;
        height: 30px;
      }
      p {
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
      }
      &:hover {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
      }
      &.active {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
        justify-content: center;
      }
    }
  }
}
</style>
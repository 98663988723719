<template>
  <div class="successfully">
    <div class="successfully_view container">
      <div class="successfully_view-img">
        <img src="../../assets/images/png/success.png" alt="success">
      </div>
      <h1 class="successfully_view-message semi-bold-title">{{ $t('toasters.successAuth') }}</h1>
      <buttton @click="$router.push('/')" class="successfully_view-btn main-button">{{ $t('buttons.onMainPage') }}</buttton>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.successfully_view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 150px;
  &-img {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &-message {
    text-align: center;
    font-size: 30px;
  }
}
</style>
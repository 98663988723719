import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupCalendar } from 'v-calendar';
import i18n from "./i18n";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";

const axiosInstance = axios.create({
    // baseURL: 'https://admin-lms.kazatomprom.kz/api/V1/',
    // baseURL: 'https://admin-lms.railways.kz/api/V1/',
    baseURL: 'https://back-lms.mydev.kz/api/V1/',
    params: {
        lang: store.getters.getLang
    }
})

// axios.defaults.baseURL = 'https://admin-lms.kazatomprom.kz/api/V1/';
// axios.defaults.baseURL = 'https://admin-lms.railways.kz/api/V1/';
axios.defaults.baseURL = 'https://back-lms.mydev.kz/api/V1/';
axios.defaults.params = {
    lang: store.getters.getLang
};

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

const app = createApp(App)
    .use(setupCalendar, {})
    .use(router)
    .use(i18n)
    .use(store)
app.config.silent = true
app.config.globalProperties.$axios = { ...axiosInstance }
app.config.globalProperties.$toaster = createToaster({ position: "top-right" });
app.mount('#app')

<template>
  <div class="intro">
    <div class="intro__content">
      <div class="intro__content-title main-title blocks-title">
        <h1 class="intro__content-title-txt">{{ courseTitle }}</h1>
        <div class="intro__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('education.intro') }}</p>
        </div>
      </div>

      <div class="intro__content-introduction blocks">
        <div class="intro__content-introduction-back">
          <button @click="$router.go(-1)">
            <img src="@/assets/icons/back-btn.svg" alt="back-btn">
            <p class="main-title">{{ $t('buttons.back') }}</p>
          </button>
        </div>
        <h2 class="intro__content-introduction-title main-title">{{ $t('education.intro') }}</h2>
        <p v-html="courseIntro"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      courseTitle: '',
      courseIntro: '',
    };
  },
  methods: {
    fetchIntroData(educationId) {
      this.$axios
          .get(`courses/${educationId}/modules`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            const courseData = response.data.course;
            this.courseTitle = courseData.title;
            this.courseIntro = courseData.introduction;
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  created() {
    const educationId = this.$route.params.educationId;
    this.fetchIntroData(educationId);
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.intro {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-introduction {
      display: flex;
      flex-direction: column;
      padding: 40px;
      gap: 20px;
      &-back {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        button {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      &-title {
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
      }
    }
  }
}
</style>

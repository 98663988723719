import { createI18n } from "vue-i18n";
import store  from "./store";

let appLanguage = 'en'
if (localStorage.getItem("lang")) {
    appLanguage = localStorage.getItem("lang")
} else {
    appLanguage = navigator.language.startsWith("ru") ? 'ru' : navigator.language.startsWith("kk") ? 'kz' : 'en'
}
export default createI18n({
    locale: appLanguage,
    fallbackLocale: "en",
    messages: {
        en: require("./locales/en.json"),
        ru: require("./locales/ru.json"),
        kz: require("./locales/kz.json"),
    },
});
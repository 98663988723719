<template>
  <div class="calendarpage">
    <div class="calendarpage__content">
      <div class="calendarpage__content-title main-title blocks-title">
        <h1 class="calendarpage__content-title-txt">{{ $t('calendar') }}</h1>
        <div class="calendarpage__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p>{{ $t('calendar') }}</p>
        </div>
      </div>
      <div class="calendarpage__content-date blocks">
        <DatePicker
            :min-date="new Date()"
            @dayclick="handleDateClick"
            mode="date"
            locale="en"
            expanded
            transparent
            borderless
        />
        <div class="calendarpage__content-date-btns">
          <button @click="showModal = true" class="calendarpage__content-date-btns-action">
            {{ $t('buttons.eventsBtn') }}
          </button>
        </div>
      </div>

      <ModalComponent :is-open="isOpen" @close="isOpen = false">
        <EventsModal
            v-if="showModal"
            @close="showModal = false"
            class="calendarpage__content-events"
            :selectedDate="selectedDate"
            @createEvent="createEvent"
        />
      </ModalComponent>
    </div>
  </div>
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import EventsModal from "@/components/modal/EventsModal.vue";

export default {
  components: {
    EventsModal,
    Calendar,
    DatePicker
  },
  data() {
    return {
      date: new Date(),
      showModal: false,
      isOpen: false,
      selectedDate: "",
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    handleDateClick(date) {
      const currentDate = new Date();
      if (date.date >= currentDate) {
        this.selectedDate = date.id
        this.showModal = true;
      }
    },
    createEvent(eventData) {
      this.$axios
          .post('user-events/create', eventData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
          .then((response) => {
            this.$toaster.success(this.$t('toasters.eventsCreate'));
            this.showModal = false;
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendarpage {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-date {
      padding: 40px;
      &-btns {
        padding-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        button {
         cursor: pointer;
        }
        &-action {
          border: 1px solid $mainTextColor;
          border-radius: 10px;
          padding: 10px 40px;
        }
      }
    }
  }
}
</style>
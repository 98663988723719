<template>
  <div class="courses">
    <div class="courses__card">
      <div class="courses__card_img">
        <img :src="course.image" alt="course-img">
      </div>
      <div class="courses__card_content">
        <div class="courses__card_content-title">
          <p class="courses__card_content-title-name">{{ course.title }}</p>
          <p class="courses__card_content-title-lessons">{{ course.lessons_count }}  {{ $t('education.lessonsCount') }}</p>
        </div>
        <div class="courses__card_content-desc">
          <p class="courses__card_content-desc-txt" v-html="course.description ">
          </p>
        </div>
        <div class="courses__card_content-btn">
          <buttton
              class="main-button"
              v-if="typeButtonCard"
              @click="goToCourse"
          >
            {{ $t('buttons.goBtn') }}
          </buttton>
          <span
              class="courses__card_content-btn-schedule"
              v-if="typeButtonCard && course.is_scheduled_course === 0"
              @click="toSchedule"
          >
            {{ $t('buttons.schedule') }}
            <img src="@/assets/icons/calendar.svg" alt="calendar">
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['course', 'typeButtonCard'],
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    goToCourse() {
      this.$router.push({ name: 'education', params: { educationId: this.course.id } });
    },
    toSchedule() {
      this.$axios
          .post(`courses/${this.course.id}/scheduled/create`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
              }
          )
          .then((response) => {
            this.$toaster.success(response.data.message);
            location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.courses {
  width: 100%;
  padding-top: 40px;
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
  &__card {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    overflow: hidden;
    height: 600px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    &_img {
      max-width: 840px;
      height: 250px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media screen and (max-width: $tablet) {
          height: 187px;
        }
      }
    }
    &_content {
      padding: 20px 40px 40px 40px;
      overflow: hidden;
      @media screen and (max-width: $tablet) {
        padding: 20px;
      }
      &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;
        &-name {
          font-size: 24px;
          height: 60px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          @media screen and (max-width: $tablet) {
            font-size: 20px;
          }
          @media screen and (max-width: $mobile) {
            font-size: 16px;
          }
        }
      }
      &-desc {
        padding-top: 20px;
        height: 115px;
        overflow: hidden;
        &-txt {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
      &-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 50px;
        display: flex;
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
        span {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
<template>
  <div class="finaltest">
    <div class="finaltest__content">
      <div class="finaltest__content-title main-title blocks-title">
        <h1 class="finaltest__content-title-txt"> {{ $t('tests.finalTest') }}</h1>
        <div class="finaltest__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('tests.finalTest') }}</p>
        </div>
      </div>

      <div class="finaltest__content_info blocks">
        <div class="finaltest__content_info-back">
          <button @click="backToMain">
            <img src="@/assets/icons/back-btn.svg" alt="back-btn">
            <p class="main-title">{{ $t('buttons.back') }}</p>
          </button>
        </div>
        <Loader v-if="loading"/>
        <h2 class="semi-bold-title">{{ finalTest.title }}</h2>
        <div
            class="finaltest__content_info-attempts"
            v-if="finalTest.startTest === 1"
        >
          <p class="second-title">{{ $t('tests.attemptsCount') }} {{ finalTest.chance }}</p>
          <div
              class="finaltest__content_info-attempts-lastresult"
              v-if="lastFinalTestResult"
          >
            <p class="second-title">{{ $t('tests.lastScore') }} {{ lastFinalTestResult.score }}</p>
          </div>
          <div
              class="finaltest__content_info-attempts-lastresult"
              v-else
          >
            <p class="second-title">{{ $t('tests.lastScore') }} {{ $t('tests.notPassTest') }}</p>
          </div>
          <p class="second-title">{{ $t('tests.warningTest') }}</p>
          <div class="finaltest__content_info-attempts-btn">
            <button class="main-button" @click="startFinalTest">{{ $t('buttons.startTest') }}</button>
          </div>
        </div>
        <div
            class="finaltest__content_info-noattempts"
            v-else
        >
          <div
              class="finaltest__content_info-noattempts-lastresult"
              v-if="lastFinalTestResult"
          >
            <span class="second-title">{{ $t('tests.lastScore') }} {{ lastFinalTestResult.score }}</span>
          </div>
          <div
              class="finaltest__content_info-noattempts-testingresult"
              v-if="lastFinalTestData && lastFinalTestData.length > 0"
          >
            <div
                class="finaltest__content_info-noattempts-testingResult"
                v-for="(result, idx) in lastFinalTestData"
                :key="idx"
            >
              <p class="second-title">{{ $t('tests.questionsCount') }} {{ result.questions_count }}</p>
              <p class="second-title">{{ $t('tests.correctAnswersCount') }} {{ result.is_correct_count }}</p>
              <p class="second-title">{{ $t('tests.incorrectAnswersCount') }} {{ result.is_incorrect_count }}</p>
              <button class="finaltest__content_info-noattempts-testingResult-btn" @click="viewFinalTestingResult(result.id)">
                <p class="main-title">{{ $t('buttons.more') }}</p>
              </button>
            </div>
          </div>
          <p class="finaltest__content_info-noattempts-message second-title" v-if="finalTest.chance === 0">{{ $t('errors.noAttempts') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Loader from '../UI/Loader.vue';

export default {
  components: {
    Loader
  },
  data() {
    return {
      finalTest: {},
      lastFinalTestResult: null,
      lastFinalTestData: [],
      loading: false,
    };
  },
  methods: {
    startFinalTest() {
      const educationId = this.$route.params.educationId;
      const finalTestId = this.$route.params.finalTestId;
      this.$axios
          .get(`courses/${educationId}/courseTests/${finalTestId}/start`)
          .then((response) => {
            const finalTestData = response.data.courseTest;
            store.commit('SET_FINAL_TEST_DATA', finalTestData);
            this.$router.push({
              name: 'education-start-final-test',
              params: {
                educationId,
                finalTestId,
              },
            });
          })
          .catch((error) => {
            console.error(error);
          });
    },
    fetchFinalTestData(educationId, finalTestId) {
      this.loading = true;
      this.$axios
          .get(`courses/${educationId}/courseTests/${finalTestId}`)
          .then((response) => {
            this.finalTest = response.data.courseTest;
            this.lastFinalTestResult = response.data.lastCourseTestResult;
            this.lastFinalTestData = response.data.lastTests;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false; // Завершение загрузки
          });
    },
    viewFinalTestingResult(resultId) {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education-testing-result', params: { educationId: educationId, resultId: resultId } });
    },
    backToMain() {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education', params: { educationId: educationId } });
    },
  },
  created() {
    const educationId = this.$route.params.educationId;
    const finalTestId = this.$route.params.finalTestId;
    this.fetchFinalTestData(educationId, finalTestId);
  },
};
</script>

<style lang="scss" scoped>
.finaltest {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding: 40px;
      &-back {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        button {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      &-attempts {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 20px;
        width: 100%;
        &-btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          button {
            width: 50%;
            @media screen and (max-width: $mobile) {
              width: 100%;
            }
          }
        }
      }
      &-noattempts {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 20px;
        width: 100%;
        &-message {
          color: #CE0000;
        }
        &-testingResult {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-bottom: 20px;
          gap: 20px;
          &-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: underline;
            width: auto;
          }
        }
      }
    }
  }
}
</style>
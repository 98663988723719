<template>
  <div class="participants">
    <div class="participants__content">
      <div class="participants__content-title main-title blocks-title">
        <h1 class="participants__content-title-txt">{{ $t('education.participants') }}</h1>
        <div class="participants__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('education.participants') }}</p>
        </div>
      </div>

      <div class="participants__content-table blocks">
        <div class="participants__content-table-header blocks" style="position: relative;">
          <p>{{ $t('education.listParticipants') }}</p>
          <input
              class="participants__content-table-header_search"
              v-model="search"
              :placeholder="$t('search')"
              @input="searchText"
          />
        </div>

        <table class="custom-table">
          <thead>
          <tr>
            <th class="regular-txt">#</th>
            <th class="regular-txt">{{ $t('auth.fullName') }}</th>
            <th class="regular-txt">{{ $t('course') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in displayedTableData" :key="index">
            <td class="regular-txt">{{ row.id }}</td>
            <td class="regular-txt">{{ row.name }}</td>
            <td class="regular-txt">{{ row.courseName }}</td>
          </tr>
          </tbody>
        </table>

        <div class="participants__content-table-pages">
          <div class="participants__content-table-pages-pagination">
            <button
                class="participants__content-table-pages-pagination-arrow"
                @click="prevPage"
                :disabled="currentPage === 1"
            >
              &laquo;
            </button>
            <button
                v-for="page in paginationPages"
                :key="page"
                class="participants__content-table-pages-pagination-item"
                :class="{ 'active-page': page === currentPage }"
            >
              {{ currentPage }}
            </button>
            <button
                class="participants__content-table-pages-pagination-arrow"
                @click="nextPage"
                :disabled="currentPage === totalPageCount"
            >
              &raquo;
            </button>
          </div>
        </div>
      </div>

      <div
          class="participants__content-manager blocks"
      >
        <p class="main-title">{{ $t('education.gradesParticipants') }}</p>
        <table class="custom-table">
          <thead>
          <tr>
            <th class="regular-txt">#</th>
            <th class="regular-txt">{{ $t('auth.fullName') }}</th>
            <th class="regular-txt">{{ $t('course') }}</th>
            <th class="regular-txt">{{ $t('auth.organization') }}</th>
            <th class="regular-txt">{{ $t('tests.ratingModuleScore') }}</th>
            <th class="regular-txt">{{ $t('tests.ratingCourseScore') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in managerData" :key="index">
            <td class="regular-txt">{{ row.id }}</td>
            <td class="regular-txt">{{ row.name, row.email }}</td>
            <td class="regular-txt">{{ row.courseName }}</td>
            <td class="regular-txt">{{ row.organizationName }}</td>
            <td class="regular-txt">{{ row.module_test_score }}</td>
            <td class="regular-txt">{{ row.course_test_score }}</td>
          </tr>
          </tbody>
        </table>
        <div class="participants__content-manager-pages">
          <div class="participants__content-manager-pages-pagination">
            <button
                class="participants__content-manager-pages-pagination-arrow"
                @click="prevRatingPage"
                :disabled="currentPageForRatings === 1"
            >
              &laquo;
            </button>
            <button
                v-for="page in ratingsPaginationPages"
                :key="page"
                class="participants__content-manager-pages-pagination-item"
                :class="{ 'active-page': page === currentPageForRatings }"
            >
              {{ currentPageForRatings }}
            </button>
            <button
                class="participants__content-manager-pages-pagination-arrow"
                @click="nextRatingPage"
                :disabled="currentPageForRatings === totalRatingsPageCount"
            >
              &raquo;
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="participants__mobile">
      <div class="participants__mobile-title main-title blocks-title">
        <h1 class="participants__mobile-title-txt">{{ $t('education.participants') }}</h1>
        <div class="participants__mobile-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('education.participants') }}</p>
        </div>
      </div>

      <div class="participants__mobile-data blocks">
        <div class="participants__mobile-data-header blocks" style="position: relative;">
          <p>{{ $t('education.listParticipants') }}</p>
          <input
              class="participants__mobile-data-header_search"
              v-model="search"
              :placeholder="$t('search')"
              @input="searchText"
          />
        </div>

        <div class="participants__mobile-data-block">
          <div
              class="participants__mobile-data-block-info"
              v-for="(row, index) in displayedTableData" :key="index"
          >
            <p class="regular-txt"># {{ row.id }}</p>
            <p class="regular-txt">{{ $t('auth.fullName') }}: {{ row.name }}</p>
            <p class="regular-txt">{{ $t('course') }}: {{ row.courseName }}</p>
          </div>
        </div>

        <div class="participants__content-table-pages">
          <div class="participants__content-table-pages-pagination">
            <button
                class="participants__content-table-pages-pagination-arrow"
                @click="prevPage"
                :disabled="currentPage === 1"
            >
              &laquo;
            </button>
            <button
                v-for="page in paginationPages"
                :key="page"
                class="participants__content-table-pages-pagination-item"
                :class="{ 'active-page': page === currentPage }"
            >
              {{ currentPage }}
            </button>
            <button
                class="participants__content-table-pages-pagination-arrow"
                @click="nextPage"
                :disabled="currentPage === totalPageCount"
            >
              &raquo;
            </button>
          </div>
        </div>
      </div>

      <div
          class="participants__mobile-managerData blocks"
          v-if="getUser.role_name === 'manager' || getUser.role_name === 'admin'"
      >
        <p class="main-title">{{ $t('education.gradesParticipants') }}</p>
        <div class="participants__mobile-data-block">
          <div
              class="participants__mobile-data-block-info"
              v-for="(row, index) in managerData" :key="index"
          >
            <p class="regular-txt"># {{ row.id }}</p>
            <p class="regular-txt">{{ $t('auth.fullName') }}: {{ row.name }}</p>
            <p class="regular-txt">{{ $t('course') }}: {{ row.courseName }}</p>
            <p class="regular-txt">{{ $t('auth.organization') }}: {{ row.organizationName }}</p>
            <p class="regular-txt">{{ $t('tests.ratingModuleScore') }}: {{ row.module_test_score }}</p>
            <p class="regular-txt">{{ $t('tests.ratingCourseScore') }}: {{ row.course_test_score }}</p>
          </div>
        </div>

        <div class="participants__content-manager-pages">
          <div class="participants__content-manager-pages-pagination">
            <button
                class="participants__content-manager-pages-pagination-arrow"
                @click="prevRatingPage"
                :disabled="currentPageForRatings === 1"
            >
              &laquo;
            </button>
            <button
                v-for="page in ratingsPaginationPages"
                :key="page"
                class="participants__content-manager-pages-pagination-item"
                :class="{ 'active-page': page === currentPageForRatings }"
            >
              {{ currentPageForRatings }}
            </button>
            <button
                class="participants__content-manager-pages-pagination-arrow"
                @click="nextRatingPage"
                :disabled="currentPageForRatings === totalRatingsPageCount"
            >
              &raquo;
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
        class="participants__export blocks"
        v-if="getUser.role_name === 'manager' || getUser.role_name === 'admin'"
    >
      <p class="main-title">{{ $t('education.exportParticipants') }}</p>
      <div class="participants__export-btn">
        <button
            class="main-button"
            @click="exportRatings"
        >
          {{ $t('buttons.exportRatings') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import { saveAs } from 'file-saver';
export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      pageData: [],
      pageForRatingsData: [],
      managerData: [],
      currentPage: null,
      currentPageForRatings: null,
      links: [],
      search: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.currentPage) {
      localStorage.removeItem('currentPage');
      next();
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
    displayedTableData() {
      const filteredData = this.tableData.filter((row) => {
        const searchString = this.search.toLowerCase();
        return (
            row.name.toLowerCase().includes(searchString) ||
            row.courseName.toLowerCase().includes(searchString)
        );
      });

      const startIndex = (this.currentPage - 1) * this.pageData.per_page;
      const endIndex = startIndex + this.pageData.per_page;
      return filteredData.slice(startIndex, endIndex);
    },
    totalPageCount() {
      return Math.ceil(this.tableData.length / this.pageData.per_page);
    },
    paginationPages() {
      const pages = [];
      for (let i = 1; i <= this.totalPageCount; i++) {
        pages.push(i);
      }
      return pages;
    },
    totalRatingsPageCount() {
      return Math.ceil(this.managerData.length / this.pageForRatingsData.per_page);
    },
    ratingsPaginationPages() {
      const pages = [];
      for (let i = 1; i <= this.totalRatingsPageCount; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    searchText() {
      this.currentPage = 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPageCount) {
        this.pageData.next_page_url;
        this.savePageToLocalStorage();
        this.fetchParticipants();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.pageData.prev_page_url;
        this.savePageToLocalStorage();
        this.fetchParticipants();
      }
    },
    nextRatingPage() {
      if (this.currentPageForRatings < this.totalRatingsPageCount) {
        this.pageForRatingsData.next_page_url;
        this.saveRatingsPageToLocalStorage();
        this.fetchRatings();
      }
    },
    prevRatingPage() {
      if (this.currentPageForRatings > 1) {
        this.pageForRatingsData.prev_page_url;
        this.saveRatingsPageToLocalStorage();
        this.fetchRatings();
      }
    },
    async fetchParticipants() {
      const educationId = this.$route.params.educationId;
      await this.$axios
          .get(`courses/${educationId}/users`, {
            params: {
              page: localStorage.getItem('currentPage') || 1,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            this.tableData = response.data.users.data;
            this.pageData = response.data.users;
            this.currentPage = this.pageData.current_page;
            this.links = this.pageData.links;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    async fetchRatings() {
      const educationId = this.$route.params.educationId;
      await this.$axios
          .get(`courses/${educationId}/ratings`, {
            params: {
              page: localStorage.getItem('currentPageForRatings') || 1,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            this.managerData = response.data.users.data;
            this.pageForRatingsData = response.data.users;
            this.currentPageForRatings = response.data.users.current_page;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    async exportRatings() {
      const educationId = this.$route.params.educationId;
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      };
      headers['Accept-Encoding'] = 'gzip';

      await this.$axios
          .post(`courses/${educationId}/ratings/export`, {}, { headers, responseType: 'arraybuffer' })
          .then(async (response) => {
            const rawFile = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(rawFile);
            link.download = 'Отчёты.xlsx';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
    },
    savePageToLocalStorage() {
      localStorage.setItem('currentPage', this.currentPage);
    },
    saveRatingsPageToLocalStorage() {
      localStorage.setItem('currentPageForRatings', this.currentPageForRatings);
    },
    loadPageFromLocalStorage() {
      const storedPage = localStorage.getItem('currentPage');
      this.currentPage = storedPage ? parseInt(storedPage) : 1;
    },
    loadRatingsPageFromLocalStorage() {
      const storedRatingsPage = localStorage.getItem('currentPageForRatings');
      this.currentPageForRatings = storedRatingsPage ? parseInt(storedRatingsPage) : 1;
    },
  },
  async created() {
    this.loadPageFromLocalStorage();
    this.loadRatingsPageFromLocalStorage();
    await this.fetchParticipants();
    await this.fetchRatings();
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
.participants {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: $tablet) {
      display: none;
    }
    &-table {
      padding: 40px;
      @media screen and (max-width: $tablet) {
        padding: 20px;
      }
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          gap: 20px;
        }
        input {
          padding: 10px;
          border: 1px solid #CBD5E0;
          border-radius: 4px;
          color: $mainTextColor;
        }
        p {
          font-size: 20px;
        }
      }
      &-pages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 50px;
        position: relative;
        width: 100%;
        &-pagination {
          &-item {
            padding: 7px 13px;
            border-radius: 50%;
            cursor: pointer;
            margin: 0 2px;
            color: #CBD5E0;
            &:hover {
              border: 1px solid #CBD5E0;
            }
            &.active-page {
              border: 1px solid #CBD5E0;
              border-radius: 50%;
              color: #FFFFFF;
              background: #02AEF0;
            }
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
          &-arrow {
            padding: 0 25px 0 25px;
            color: #000000;
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
    &-manager {
      display: flex;
      flex-direction: column;
      padding: 40px;
      p {
        font-size: 20px;
      }
      &-pages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-top: 50px;
        position: relative;
        &-pagination {
          &-item {
            padding: 7px 13px;
            border-radius: 50%;
            cursor: pointer;
            margin: 0 2px;
            color: #CBD5E0;
            &:hover {
              border: 1px solid #CBD5E0;
            }
            &.active-page {
              border: 1px solid #CBD5E0;
              border-radius: 50%;
              color: #FFFFFF;
              background: #02AEF0;
            }
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
          &-arrow {
            padding: 0 25px 0 25px;
            color: #000000;
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
  }

  &__mobile {
    display: none;
    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        gap: 20px;
      }
      input {
        padding: 10px;
        border: 1px solid #CBD5E0;
        border-radius: 4px;
        color: $mainTextColor;
      }
      p {
        font-size: 20px;
      }
    }
    &-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
      gap: 20px;
      &-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 10px;
        &-info {
          display: flex;
          flex-direction: column;
          background-color: #02AEF0;
          border-radius: 10px;
          padding: 20px;
          gap: 15px;
          width: 100%;
          p {
            font-size: 18px;
            color: white;
          }
        }
      }
      &-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;
        input {
          padding: 10px;
          border: 1px solid #CBD5E0;
          border-radius: 4px;
          color: $mainTextColor;
        }
        p {
          font-size: 20px;
        }
      }
    }
    &-managerData {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
      gap: 20px;
      .main-title {
        font-size: 20px;
      }
      &-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 10px;
        &-info {
          display: flex;
          flex-direction: column;
          background-color: #02AEF0;
          border-radius: 10px;
          padding: 20px;
          gap: 15px;
          width: 100%;
          p {
            font-size: 18px;
            color: white;
          }
        }
      }
    }
  }
  &__export {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 20px;
    .main-title {
      font-size: 20px;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.res-search {
  position: absolute;
  top: 120%;
  left: 0;
  width: 150%;
  padding: 15px;
}
.users {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  &__items {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 15px;
    background: rgba(1, 25, 122, 0.8);
    img {
      width: 20px;
      max-height: 20px;
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        color: black;
        font-size: 12px;
        overflow-y: auto;
        text-decoration: none !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        font-size: 15px;
        text-decoration: none !important;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.custom-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  thead {
    width: 100%;
    th {
      padding: 15px;
      background: #EDF2F7;
      text-align: start;
    }
  }
  tbody {
    td {
      padding: 15px;
      text-align: start;
      border-top: 1px solid #E2E8F0;
    }
  }
  @media screen and (max-width: $tablet) {
    th, td {
      padding: 10px;
    }
  }
}
.participants__content-table-pages-pagination-item.dots {
  cursor: default;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
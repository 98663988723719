<template>
  <div class="progress">
    <div class="progress__courses">
      <div
          class="progress__courses_card"
      >
        <img :src="data.image" alt="courseinfo" @click="goToCourses()">
        <div class="progress__courses_card-data">
          <div class="progress__courses_card-data-indicators" @click="goToCourses()">
            <div class="progress__courses_card-data-indicators-course second-title">{{ data.title }}</div>
            <div class="circular__progressbar" :style="circularProgressStyle">
              <span class="circular__progressbar-percent">{{ progressValue }}%</span>
            </div>
          </div>
          <button
              class="main-button"
              v-if="typeButton"
              @click="navigateToCourse"
          >
            {{ $t('buttons.goBtn') }}
          </button>
          <span
              class="progress__courses_card-data-delete"
              v-if="typeSpanDelete && currentTab === 'scheduled'"
              @click="deleteScheduledCard"
          >
            {{ $t('buttons.delete') }}
            <img src="@/assets/icons/calendar-delete.svg" alt="calendar-delete">
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['typeButton', 'typeSpanDelete', 'currentTab', 'data'],
  components: {
  },
  data() {
    return {
      progressValue: 0,
      speed: 50,
      courses: [],
    };
  },
  computed: {
    circularProgressStyle() {
      const progressDegrees = (this.progressValue / 100) * 360;
      const endDegrees = (this.data.progress / 100) * 360;
      const gradientEnd = Math.min(progressDegrees, endDegrees);
      return {
        background: `conic-gradient(#9893DA ${gradientEnd}deg, #ccccd5 0deg)`,
      };
    },
  },
  methods: {
    startProgressAnimation() {
      const progressInterval = setInterval(() => {
        if (this.progressValue >= this.data.progress) {
          clearInterval(progressInterval);
        } else {
          this.progressValue += 1;
          if (this.progressValue > this.data.progress) {
            this.progressValue = this.data.progress;
          }
        }
      }, this.speed);
    },
    navigateToCourse() {
      this.$router.push({ name: 'education', params: { educationId: this.data.id } });
    },
    goToCourses() {
      this.$router.push({ name: 'education', params: { educationId: this.data.id } });
    },
    deleteScheduledCard() {
      this.$axios
          .post(`courses/${this.data.id}/scheduled/delete`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
              }
          )
          .then((response) => {
            this.$toaster.success(response.data.message);
            location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
    }
  },
  mounted() {
    this.startProgressAnimation();
  },
};
</script>

<style lang="scss" scoped>

.progress {
  width: 48%;
  &__courses {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    &_card {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: start;
      cursor: pointer;
      img {
        width: 100%;
        border-radius: 10px;
      }
      &-data {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 25px;
        width: 100%;
        &-indicators {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          &-course {
            position: relative;
            font-size: 24px;
            width: 80%;
          }
        }
        &-delete {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          color: #CE0000;
          cursor: pointer;
          text-decoration: underline;
          img {
            width: 30px;
            height: 30px;
            border-radius: 0;
          }
        }
      }
    }
  }
}
.circular__progressbar {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(#9893DA 3.6deg, #FFFFFF 0deg);
  border-radius: 50%;

  &-percent {
    position: relative;
    font-size: 10px;
  }
}
.circular__progressbar::before {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #FFFFFF;
}
</style>
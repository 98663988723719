import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import store from "@/store";
import MainPersonalAcc from '@/views/personalAccount/MainPersonalAcc.vue'
import CoursesList from "@/views/personalAccount/CoursesListPage.vue";
import CalendarPage from "@/views/personalAccount/CalendarPage.vue";
import AnalyticsPage from "@/views/personalAccount/AnalyticsPage.vue";
import MyCoursesPage from "@/views/personalAccount/MyCoursesPage.vue";
import SuccessfullyPage from "@/views/authorization/SuccessfullyPage.vue";
import LoginPage from "@/views/authorization/LoginPage.vue";
import CreateAccPage from "@/views/authorization/CreateAccPage.vue";
import MyCoursesEducation from "@/views/MyCoursesEducation.vue";
import NotFound404 from "@/views/NotFound404.vue";
import EducationParticipants from "@/components/education/EducationParticipants.vue";
import EducationLessonId from "@/components/education/EducationLessonId.vue";
import EducationGrades from "@/components/education/EducationGrades.vue";
import EducationIntro from "@/components/education/EducationIntro.vue";
import EducationCertificate from "@/components/education/EducationCertificate.vue";
import EducationModuleTesting from "@/components/education/EducationModuleTesting.vue";
import EducationTestingId from "@/components/education/EducationTestingId.vue";
import UserNotifications from "@/views/UserNotifications.vue";
import EducationFinalTest from "@/components/education/EducationFinalTest.vue";
import EducationFinalTestingId from "@/components/education/EducationFinalTestingId.vue";
import EducationResult from '@/components/education/EducationResult.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/successfully',
        name: 'successfully',
        component: SuccessfullyPage
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: NotFound404
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/personal',
        name: 'personal',
        component: MainPersonalAcc,
        children: [
            {
                path: 'courses-list',
                name: 'courses-list',
                component: CoursesList,
            },
            {
                path: 'calendar',
                name: 'calendar',
                component: CalendarPage,
            },
            {
                path: 'mycourses',
                name: 'mycourses',
                component: MyCoursesPage,
            },
            {
                path: 'notifications',
                name: 'notifications',
                component: UserNotifications,
            },
            {
                path: '/personal/mycourses/:educationId',
                name: 'education',
                component: MyCoursesEducation,
            },
            {
                path: '/personal/mycourses/:educationId/participants',
                name: 'education-participants',
                component: EducationParticipants,
            },
            {
                path: '/personal/mycourses/:educationId/grades',
                name: 'education-grades',
                component: EducationGrades,
            },
            {
                path: '/personal/mycourses/:educationId/certificate',
                name: 'education-certificate',
                component: EducationCertificate,
            },
            {
                path: '/personal/mycourses/:educationId/introduction',
                name: 'education-intro',
                component: EducationIntro,
            },
            {
                path: '/personal/mycourses/:educationId/lesson/:lessonId',
                name: 'education-lesson',
                component: EducationLessonId,
            },
            {
                path: '/personal/mycourses/:educationId/modules/:moduleId/test/:testId',
                name: 'education-test',
                component: EducationModuleTesting,
            },
            {
                path: '/personal/mycourses/:educationId/modules/:moduleId/test/:testId/start',
                name: 'education-start-test',
                component: EducationTestingId,
            },
            {
                path: '/personal/mycourses/:educationId/userTestResults/:resultId',
                name: 'education-testing-result',
                component: EducationResult,
            },
            {
                path: '/personal/mycourses/:educationId/finaltest/:finalTestId',
                name: 'education-final-test',
                component: EducationFinalTest,
            },
            {
                path: '/personal/mycourses/:educationId/finaltest/:finalTestId/start',
                name: 'education-start-final-test',
                component: EducationFinalTestingId,
            },
            {
                path: 'analytics',
                name: 'analytics',
                component: AnalyticsPage,
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
})

router.beforeEach((to, from, next) => {
    // Check if the user is already authenticated
    if (store.getters.getAuth) {
        // If authenticated, prevent access to 'login' and 'create-account' pages
        if (to.name === 'login') {
            // Redirect to the home page or another appropriate page
            next('/');
        } else {
            // Allow access to other routes
            next();
        }
    } else {
        // User is not authenticated, continue with the regular logic
        // Check if the route requires authentication
        if (to.meta.requiresAuth) {
            // Check if the user is authenticated
            if (store.getters.getAuth) {
                // User is authenticated, proceed to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/login');
            }
        } else {
            next();
        }
    }
});

export default router

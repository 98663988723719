<template>
  <div class="edugrades">
    <div class="edugrades__content">
      <div class="edugrades__content-title main-title blocks-title">
        <h1 class="edugrades__content-title-txt">{{ $t('education.grades') }}</h1>
        <div class="edugrades__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('education.grades') }}</p>
        </div>
      </div>

      <div class="edugrades__content_grades">
        <GradesEduMainBlock
            class="education__content_grades-block"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GradesEduMainBlock from "@/components/education/EducationMainBlock.vue";

export default {
  components: {
    GradesEduMainBlock
  },
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.edugrades {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
h2 {
  font-size: 30px;
  padding-top: 40px;
}
</style>
<template>
  <div class="lesson">
    <div class="lesson__content">
      <div class="lesson__content-title main-title blocks-title">
        <h1 class="lesson__content-title-txt">{{ lessonTitle }}</h1>
        <div class="lesson__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p @click="backToMain">{{ courseTitle }}</p>
        </div>
      </div>

      <div class="lesson__content-block blocks">
        <div class="lesson__content-block-navigation">
          <div class="lesson__content-block-navigation-back">
            <button @click="backToMain">
              <img src="@/assets/icons/back-btn.svg" alt="back-btn">
              <p class="main-title">{{ $t('buttons.back') }}</p>
            </button>
          </div>
          <div class="lesson__content-block-navigation-stages">
            <button @click="prevStage" :disabled="currentPage === 1" ref="prevButton">
              <img src="@/assets/icons/arrow-left.svg" alt="arrow-left">
            </button>
            <span>{{ $t('education.stage') }} {{ currentPage }}/{{ totalPageCount }}</span>
            <button @click="nextStage" :disabled="currentPage === totalPageCount || video === null" ref="nextButton">
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
        </div>

        <div class="lesson__content-block-presentation" v-if="presentationLoaded && currentStage === 'presentation'">
          <div>
            <p class="semi-bold-title">{{ $t('education.presentation') }}. {{ title }}</p>
            <div class="lesson__content-block-presentation-iframe" v-html="presentation"></div>
          </div>
          <div class="lesson__content-block-presentation-btn" v-if="video === null">
            <button class="main-button" @click="lessonFinished">{{ $t('buttons.watchLesson') }}</button>
          </div>
        </div>

        <div class="lesson__content-block-video" v-if="video && presentationLoaded && currentStage === 'video'">
          <p class="semi-bold-title">{{ $t('education.video') }}. {{ title }}</p>
          <iframe :src="video" ref="videoPlayer" width="100%" height="650" frameborder="10" allowfullscreen></iframe>
          <div class="lesson__content-block-video-btn">
            <button class="main-button" @click="lessonFinished">{{ $t('buttons.watchLesson') }}</button>
          </div>
        </div>

        <div class="lesson__content-block-nextnav">
          <span>{{ lessonTitle }}</span>
          <div v-if="showFinishButton" class="lesson__content-block-nextnav-btn">
            <button class="next-btn" @click="goToNextLesson">
              <p class="main-title">{{ $t('education.nextLesson') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
          <div v-if="showTestButton" class="lesson__content-block-nextnav-testBtn">
            <button class="next-btn" @click="goToTestModule">
              <p class="main-title">{{ $t('buttons.goToTest') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      lessonTitle: '',
      courseTitle: '',
      presentation: '',
      title: '',
      audio: '',
      video: '',
      courseId: null,
      currentPage: 1,
      totalPageCount: this.video === null ? 1 : 2,
      currentStage: 'presentation',
      presentationLoaded: false,
      videoPlayedHalfway: false,
      showFinishButton: false,
      showTestButton: false,
      audioFinished: false,
      finalTestData: {},
      nextLesson: {},
      testModule: {},
    };
  },
  computed: {
  },
  methods: {
    backToMain() {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education', params: { educationId: educationId } });
    },
    prevStage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.currentStage = 'presentation';
        this.savePageState();
      }
    },
    nextStage() {
      if (this.currentPage < this.totalPageCount && this.video !== null) {
        this.currentPage++;
        this.currentStage = 'video';
        this.savePageState();
      }
    },
    savePageState() {
      localStorage.setItem('currentPage', this.currentPage);
      localStorage.setItem('currentStage', this.currentStage);
    },
    fetchLessonData() {
      const educationId = this.$route.params.educationId;
      const lessonId = this.$route.params.lessonId;
      this.$axios
          .get(`courses/${educationId}/lessons/${lessonId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            const lessonData = response.data.lesson;
            const courseData = response.data.course;
            this.lessonTitle = lessonData.title;
            this.courseTitle = courseData.title;
            this.courseId = courseData.id;
            this.presentation = lessonData.presentation;
            this.audio = lessonData.audio;
            this.video = lessonData.video;
            this.title = lessonData.title
            this.presentationLoaded = true;
            if (this.video === null) {
              this.totalPageCount = 1;
            } else {
              this.totalPageCount = 2;
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
    restorePageState() {
      const savedPage = localStorage.getItem('currentPage');
      const savedStage = localStorage.getItem('currentStage');

      if (savedPage !== null) {
        this.currentPage = parseInt(savedPage, 10);
      }

      if (savedStage !== null) {
        this.currentStage = savedStage;
      }
    },
    lessonFinished() {
      const educationId = this.$route.params.educationId;
      const lessonId = this.$route.params.lessonId;
      const url = `courses/${educationId}/lessons/${lessonId}/finished`;
      this.$axios.post(url,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((res) => {
            this.nextLesson = res.data.nextLesson;
            this.testModule = res.data.test;
            this.finalTestData = res.data.course.courseTest;
            if (this.nextLesson) {
              this.showFinishButton = true;
              this.showTestButton = false;
            } else {
              this.showFinishButton = false;
              this.showTestButton = true;
            }
            if (this.finalTestData.status === 1) {
              this.showFinalTestButton = true;
            } else {
              this.showFinalTestButton = false;
            }
            this.$toaster.success(res.data.message);
          })
          .catch((error) => {
            console.error(error);
          });
    },
    async goToNextLesson() {
      const educationId = this.$route.params.educationId;
      const lessonId = this.$route.params.lessonId;
      const url = `courses/${educationId}/lessons/${lessonId}/finished`;
      this.showFinishButton = false;
      try {
        await this.$axios.post(url, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const nextLessonId = this.nextLesson.id;
        const nextLessonUrl = `courses/${educationId}/lessons/${nextLessonId}`;
        const nextLessonResponse = await this.$axios.get(nextLessonUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        const nextLessonData = nextLessonResponse.data.lesson;

        this.lessonTitle = nextLessonData.title;
        this.presentation = nextLessonData.presentation;
        this.audio = nextLessonData.audio;
        this.video = nextLessonData.video;
        this.title = nextLessonData.title;
        this.presentationLoaded = true;
        this.currentPage = 1;
        this.currentStage = 'presentation';

        this.$router.push({
          name: 'education-lesson',
          params: {
            educationId,
            lessonId: nextLessonId,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async goToTestModule() {
      const educationId = this.$route.params.educationId;
      const lessonId = this.$route.params.lessonId;

      try {
        const response = await this.$axios.get(`courses/${educationId}/modules`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        const modules = response.data.modules;
        const moduleWithLesson = modules.find(module => module.lessons.some(lesson => lesson.id === parseInt(lessonId)));

        if (moduleWithLesson) {
          const moduleId = moduleWithLesson.id;
          this.$router.push({ name: 'education-test', params: { educationId, moduleId, testId: this.testModule.id } });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.restorePageState();
    this.$router.beforeEach((to, from, next) => {
      if (to.name !== 'education-lesson') {
        localStorage.removeItem('currentPage');
        localStorage.removeItem('currentStage');
      }
      next();
    });
    this.fetchLessonData();
    this.goToNextLesson();
  },
  watch: {
    "$route.params"() {
      this.fetchLessonData();
    }
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  width: 100%;
  color: $mainTextColor;
  @media screen and (max-width: $laptop) {
    width: 65%;
  }
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      &-navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &-stages {
          display: flex;
          justify-content: end;
          gap: 30px;
          width: 100%;
          img {
            cursor: pointer;
          }
        }
        &-back {
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          width: 100%;
          button {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
      &-presentation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 20px;
        padding: 20px 0;
        p {
          font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
        }
        &-iframe {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px 0;
          width: 100%;
          height: 650px;
        }
        &-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
      &-video {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        border-radius: 10px;
        gap: 20px;
        padding: 20px 0;
        &-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        p {
          font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
        }
        iframe {
          @media screen and (max-width: $tablet) {
            height: 340px;
          }
          @media screen and (max-width: $mobile) {
            height: 240px;
          }
        }
      }

      &-nextnav {
        display: flex;
        padding-top: 40px;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $tablet) {
          padding-top: 0;
        }
        span {
          text-align: center;
          padding: 0 40px;
        }
        &-btn {
          button {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        &-testBtn {
          button {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}
.disabled {
  opacity: 0.5;
}
</style>

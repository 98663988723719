<template>
  <div
      class="edumenu blocks"
  >
    <router-link
        :to="{ name: 'education'}"
        class="edumenu_mycourse-navigation"
        :class="{ 'active': $route.name === 'education' || $route.name === 'education-lesson'}"
    >
      <div class="edumenu_mycourse-navigation-title">
        <div class="edumenu_mycourse-navigation-title-img">
          <img src="../../assets/icons/mycourses.svg" alt="mycourses">
        </div>
        <p class="regular-txt">{{ courseTitleMenu }}</p>
      </div>
    </router-link>
    <router-link
        :to="{ name: 'education-participants', params: { educationId: $route.params.educationId } }"
        class="edumenu_mycourse-navigation"
        :class="{ 'active': $route.name === 'education-participants' }"
        v-if="getUser.role_name === 'manager' || getUser.role_name === 'admin'"
    >
      <div class="edumenu_mycourse-navigation-title">
        <div class="edumenu_mycourse-navigation-title-img">
          <img src="../../assets/icons/participant.svg" alt="participant">
        </div>
        <p class="regular-txt">{{ $t('education.participants') }}</p>
      </div>
    </router-link>
    <router-link
        :to="{ name: 'education-grades'}"
        class="edumenu_mycourse-navigation"
        :class="{ 'active': $route.name === 'education-grades' }"
    >
      <div class="edumenu_mycourse-navigation-title">
        <div class="edumenu_mycourse-navigation-title-img">
          <img src="../../assets/icons/list.svg" alt="grades">
        </div>
        <p class="regular-txt">{{ $t('education.grades') }}</p>
      </div>
    </router-link>
    <router-link
        :to="{ name: 'education', hash: '#general' }"
        class="edumenu_mycourse-navigation"
        @click="scrollToModule('modules')"
        :class="{ 'blocks-active': isBlockActive }"
    >
      <div class="edumenu_mycourse-navigation-title">
        <div class="edumenu_mycourse-navigation-title-img">
          <img src="../../assets/icons/block_folder.svg" alt="block_folder">
        </div>
        <p class="regular-txt">{{ $t('education.general') }}</p>
      </div>
    </router-link>
    <div class="edumenu_mycourse-navigation-blocks" v-if="isBlockActive">
      <a
          class="edumenu_mycourse-navigation-blocks-link"
          v-for="(module, index) in modules" :key="module.id"
          :href="'#module-' + module.title"
      >
        <div
            class="edumenu_mycourse-navigation-blocks-link-img"
        >
          <img src="../../assets/icons/block_folder.svg" alt="block_folder">
        </div>
        <p class="regular-txt">{{ module.title }}</p>
      </a>
    </div>
    <router-link
        :to="{ name: 'education-certificate' }"
        class="edumenu_mycourse-navigation"
        :class="{ 'active': $route.name === 'education-certificate' }"
    >
      <div class="edumenu_mycourse-navigation-title">
        <div class="edumenu_mycourse-navigation-title-img">
          <img src="../../assets/icons/block_folder.svg" alt="block_folder">
        </div>
        <p class="regular-txt">{{ $t('education.certificate') }}</p>
      </div>
    </router-link>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modules: [],
      courseTitleMenu: '',
      isBlockActive: false,
    };
  },
  created() {
    const educationId = this.$route.params.educationId;
    this.fetchModules(educationId);
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    scrollToModule(moduleId) {
      this.isBlockActive = !this.isBlockActive;
      const element = document.getElementById(moduleId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    fetchModules(educationId) {
      this.$axios.get(`courses/${educationId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
      )
          .then((response) => {
            const courseData = response.data.course;
            this.courseTitleMenu = courseData.title;
            this.modules = courseData.modules;
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.edumenu {
  display: flex;
  flex-direction: column;
  min-width: 25%;
  gap: 10px;
  padding: 30px 20px 30px 20px;
  &_mycourse {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 20px;

    &-navigation {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      cursor: pointer;
      gap: 20px;
      &-title {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      &-blocks {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;
        &-link {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 20px;
          cursor: pointer;
          gap: 20px;
          p {
            font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
          }
          &:hover {
            background-color: rgba(114, 114, 126, 0.3);
            border-radius: 10px;
          }

        }
      }

      img {
        width: 30px;
        height: 30px;
      }

      p {
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
      }

      &:hover {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
      }

      &.active {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
        justify-content: center;
      }
    }
  }
}
.blocks-active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
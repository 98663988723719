<template>
  <div class="event">
    <div class="event__modal blocks">
      <div class="event__modal_header">
        <h2 class="main-title">{{ $t('buttons.eventsBtn') }}</h2>
        <div class="event__modal_header-close" @click="closeModal">✕</div>
      </div>

      <div class="event__modal_name">
        <label for="eventName">{{ $t('events.eventName') }}</label>
        <input id="eventName" type="text" v-model="eventName" />
      </div>

      <div class="event__modal_datetime">
        <label>{{ $t('date') }}</label>
        <div class="event__modal_datetime-inputs" v-if="eventDate">
          <input type="date" v-model="eventDate"/>
          <input type="time" v-model="eventTime"/>
        </div>
      </div>

      <div class="event__modal_type">
        <label for="eventType">{{ $t('events.eventType') }}</label>
        <input id="eventType" type="text" v-model="eventType" />
      </div>

      <div class="event__modal_desc">
        <label for="eventDescription">{{ $t('description') }}</label>
        <textarea id="eventDescription" v-model="eventDescription"></textarea>
      </div>

      <div class="event__modal_duration">
        <label>{{ $t('events.eventDuration') }}</label>
        <div class="event__modal_duration-radio">
          <div class="event__modal_duration-radio-without">
            <input
                type="radio"
                v-model="durationType"
                value="none"
            />
            <label for="none">{{ $t('events.eventNoDuration') }}</label>
          </div>
          <div class="event__modal_duration-radio-with">
            <div class="event__modal_duration-radio-with-until">
              <input
                  type="radio"
                  v-model="durationType"
                  value="untilDate"
              />
              <label for="untilDate">{{ $t('events.untilDate') }}</label>
            </div>
            <div
                v-if="durationType === 'untilDate'"
                class="event__modal_duration-radio-with-date"
            >
              <input type="date" v-model="endDate"/>
              <input type="time" v-model="time"/>
            </div>
          </div>
        </div>
      </div>

      <form class="event__modal_repeat">
        <input type="checkbox" v-model="repeatDaily" :disabled="durationType === 'untilDate'"/>
        <label>{{ $t('events.eventRepeat') }}</label>
      </form>

      <div class="event__modal_create">
        <button class="main-button" @click="createEvent">{{ $t('buttons.create') }}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    selectedDate: Date,
  },
  data() {
    return {
      eventName: '',
      eventDate: new Date(),
      eventTime: '',
      eventType: '',
      eventDescription: '',
      durationType: 'none',
      time: '',
      endDate: '',
      repeatDaily: false,
    };
  },
  computed: {
    duration_date() {
      if (this.durationType === 'untilDate') {
        return `${this.endDate} ${this.time}`;
      }
      return null;
    },
  },
  mounted() {
    this.eventDate = this.selectedDate || new Date();
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    createEvent() {
      const eventData = {
        title: this.eventName,
        date: this.eventDate,
        event_type: this.eventType,
        description: this.eventDescription,
        is_duration: this.durationType === 'none' ? 0 : 1,
        duration_date: this.duration_date,
        is_event_daily: this.repeatDaily ? 1 : 0,
      };
      this.$emit('createEvent', eventData);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  position: fixed;
  color: $mainTextColor;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  &__modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 568px;
    padding: 40px;
    gap: 30px;
    z-index: 9999;
    @media screen and (max-width: $tablet) {
      max-width: 100%;
    }
    &_header {
      justify-content: space-between;
      width: 100%;
      h2 {
        font-size: 30px;
      }
      &-close {
        width: 24px !important;
        height: 24px;
        color: #000000;
        font-weight: 600;
        font-size: 24px;
        cursor: pointer;
      }
    }
    input,
    textarea {
      border-radius: 10px;
      border: 1px solid #D3D3D3;
      padding: 5px;
    }
    label {
      width: 100%;
      padding-right: 40px;
    }
    div {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &_name {
      input {
        width: 100%;
      }
    }

    &_datetime {
      &-inputs {
        display: flex;
        align-items: center;
        gap: 10px;
        input {
          max-width: 100px;
        }
      }
    }

    &_type {
      input {
        width: 100%;
      }
    }

    &_desc {
      align-items: baseline !important;
      textarea {
        width: 100%;
        height: 100px;
      }
    }

    &_duration {
      align-items: baseline !important;
      &-radio {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        &-without {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        &-with {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          &-until {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          &-date {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            input {
              max-width: 100px;
            }
          }
        }
      }
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        gap: 20px;
      }
    }

    &_repeat {
      label {
        padding-left: 10px;
      }
    }

    &_create {
      justify-content: center;
    }
  }
}
</style>

<template>
  <div class="certificate">
    <div class="certificate__content">
      <div class="certificate__content-title main-title blocks-title">
        <h1 class="certificate__content-title-txt">{{ $t('education.certificate') }}</h1>
        <div class="certificate__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('education.certificate') }}</p>
        </div>
      </div>

      <div class="certificate__content_block blocks" v-if="certificate">
        <h1 class="main-title">{{ $t('personal.congratsCertificate') }}</h1>
        <div class="certificate__content_block-btn">
          <p class="second-title">{{ $t('personal.downloadCertificate') }}</p>
          <img src="" alt="">
          <div class="certificate__content_block-btn-download">
            <a :href="certificate.path">
              <button class="main-button">{{ $t('buttons.download') }}</button>
            </a>
          </div>
        </div>
      </div>
      <div class="certificate__content_nocertificate blocks" v-if="certificate == null">
        <h1 class="main-title">{{ $t('errors.noCertificate') }}</h1>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      certificate: {},
    };
  },
  methods: {
    fetchCertificateData(educationId) {
      this.$axios
          .get(`courses/${educationId}/certificate`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            this.certificate = response.data.certificate;
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  created() {
    const educationId = this.$route.params.educationId;
    this.fetchCertificateData(educationId);
  },
};
</script>

<style lang="scss" scoped>
.certificate {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      gap: 40px;
      &-btn {
        &-download {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &_nocertificate {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      width: 100%;
    }
  }
}
h1 {
  font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.9358)), 30px);
}
</style>
<template>
  <div class="desctop-lms">
    <div class="backHeader">
      <div class="headerD container">
        <div class="headerD__top">
          <div class="headerD__top_left" @click="$router.push('/')">
            <img src="@/assets/images/png/aqparattyqsana.png" class="headerD__top_left-logo" alt="logo"/>
<!--            <div class="headerD__top_left-subLogo">-->
<!--              <img src="@/assets/images/png/KAP-technology.png" alt="subLogo" class="headerD__top_left-subLogo-img">-->
<!--            </div>-->
          </div>
          <div class="headerD__top_right">
            <div class="headerD__top_right-burger" @click="showBurgerMenu">
              <div class="burger-icon" :class="{ 'burger-active': isBurgerActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="headerD__top_right-lang">
              <div
                  class="dropdown-lang"
              >
                <img
                    style="cursor: pointer;"
                    @click.stop="toggleLangDropdown"
                    src="@/assets/icons/language-mobile.svg"
                    alt="language"
                    ref="langDropdown"
                />
                <div
                    v-if="isLangDropdownOpen"
                    class="dropdown-lang-content"
                    v-click-out-side="closeLangDropdown"
                >
                  <div
                      v-for="(item, index) in languages"
                      :key="index + 'lang'"
                      @click="changeLanguage(item.value)"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="headerD__top_right-notification" v-if="isAuthenticated">
              <div :class="{ 'notification-icon': userNotifications.length > 0 }">
                <img
                    style="cursor: pointer;"
                    src="@/assets/icons/notification-mobile.svg"
                    alt="notification"
                    @click.stop="toggleNotifications"
                    ref="notifDropdown"
                />
              </div>
              <div v-if="isNotificationsOpen" class="notification-dropdown">
                <h1 class="bold-title">{{ $t('notifications') }}</h1>
                <div
                    v-for="(notification, index) in userNotifications"
                    :key="index"
                    @click="goToNotificationsPage"
                    v-click-out-side="closeNotifDropdown"
                    class="notification-item"
                    :userNotifications="userNotifications"
                >
                  <h3 class="main-title">{{ notification.title }}</h3>
                  <p class="second-title">{{ notification.description }}</p>
                </div>
                <div v-if="userNotifications.length === 0" class="notification-empty">
                  {{ $t('noNotifications') }}
                </div>
              </div>
            </div>
            <div class="headerD__top_right-profile">
              <img
                  style="cursor: pointer;"
                  src="@/assets/icons/profile-mobile.svg"
                  alt="profile"
                  @click="toggleProfileDropdown"
                  ref="profileDropdown"
              />
              <div
                  v-if="isProfileDropdownOpen"
                  class="dropdown-profile-content"
                  v-click-out-side="closeProfileDropdown"
              >
                <template v-if="isAuthenticated && userName">
                  <div @click="goToPersonal" class="dropdown-profile-content_links">
                    <img src="@/assets/icons/header-username.svg" alt="username" />
                    <p>{{ userName }}</p>
                  </div>
                  <div @click="logoutAcc" class="dropdown-profile-content_links">
                    <img src="@/assets/icons/enter-logout.svg" alt="logout" />
                    <p>{{ $t('buttons.logout') }}</p>
                  </div>
                </template>
                <template v-else>
                  <div @click="goToLogin" class="dropdown-profile-content_links">
                    <img src="@/assets/icons/enter-logout.svg" alt="enter" />
                    <p>{{ $t('buttons.enter') }}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mobile-lms">
    <div class="backHeaderMobile">
      <div class="headerM">
        <div class="headerM__top">
          <div class="headerM__top-left" @click="$router.push('/')">
            <img src="@/assets/images/png/aqparattyqsana.png" alt="lms-logo" class="headerM__top-left-logo"/>
<!--            <div class="headerM__top-left-subLogo">-->
<!--              <img src="@/assets/images/png/KAP-technology.png" alt="subLogo" class="headerM__top-left-subLogo-img">-->
<!--            </div>-->
          </div>
          <div class="headerM__top_right">
            <div @click.prevent="showBurgerMenu" :class="{ 'burger-active': isBurgerActive }" class="headerM__top_right-burger">
              <div>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="headerM__bot" :class="{'headerM__botactive': isBurgerActive}">
          <NavMenuHeader class="headerM__bot-navmenu" />
          <div class="headerM__bot-icons">
            <div class="headerM__bot-icons-lang">
              <div
                  class="dropdown-lang"
              >
                <img
                    style="cursor: pointer;"
                    src="@/assets/icons/language-mobile.svg"
                    alt="language-mobile"
                    @click.stop="toggleLangDropdown"
                    ref="langDropdown"
                />
                <div
                    v-if="isLangDropdownOpen"
                    class="dropdown-lang-content"
                    v-click-out-side="closeProfileDropdown"
                >
                  <div
                      v-for="(item, index) in languages"
                      :key="index + 'lang'"
                      @click="changeLanguage(item.value)"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="headerM__bot-icons-notification" v-if="isAuthenticated">
              <div :class="{ 'notification-icon': userNotifications.length > 0 }">
                <img
                    style="cursor: pointer;"
                    src="@/assets/icons/notification-mobile.svg"
                    alt="notification"
                    @click.stop="toggleNotifications"
                    ref="notifDropdown"
                />
              </div>
              <div v-if="isNotificationsOpen" class="notification-dropdown">
                <h1 class="bold-title">{{ $t('notifications') }}</h1>
                <div
                    v-for="(notification, index) in userNotifications"
                    :key="index"
                    @click="goToNotificationsPage"
                    v-click-out-side="closeNotifDropdown"
                    class="notification-item"
                >
                  <h3 class="main-title">{{notification.id}}. {{ notification.title }}</h3>
                  <p class="second-title">{{ notification.description }}</p>
                </div>
                <div v-if="userNotifications.length === 0" class="notification-empty">
                  {{ $t('noNotifications') }}
                </div>
              </div>
            </div>
            <div class="headerM__bot-icons-profile">
              <img
                  style="cursor: pointer;"
                  src="@/assets/icons/profile-mobile.svg"
                  alt="profile-mobile"
                  @click.stop="toggleProfileDropdown"
              />
              <div
                  v-if="isProfileDropdownOpen"
                  class="dropdown-profile-content"
              >
                <template v-if="isAuthenticated && userName">
                  <div @click.stop="goToPersonal" class="dropdown-profile-content_links">
                    <img src="@/assets/icons/header-username.svg" alt="username" />
                    <p>{{ userName }}</p>
                  </div>
                  <div @click.stop="logoutAcc" class="dropdown-profile-content_links">
                    <img src="@/assets/icons/enter-logout.svg" alt="logout" />
                    <p>{{ $t('buttons.logout') }}</p>
                  </div>
                </template>
                <template v-else>
                  <div @click.stop="goToLogin" class="dropdown-profile-content_links">
                    <img src="@/assets/icons/enter-logout.svg" alt="enter" />
                    <p>{{ $t('buttons.enter') }}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import NavMenuHeader from "@/components/UI/NavigationMenu.vue";
import UserNotifications from "@/views/UserNotifications.vue";

export default {
  name:'HeaderVue',
  components: {UserNotifications, NavMenuHeader },
  directives: {
    clickOutSide,
  },
  data() {
    return {
      languages: [
        { id: 2, value: "kz", title: "Қазақша"},
        { id: 1, value: "ru", title: "Русский"},
        // { id: 3, value: "en", title: "English"},
      ],
      isLangDropdownOpen: false,
      isBurgerActive: false,
      isProfileDropdownOpen: false,
      isOpen: false,
      userNotifications: [],
      isNotificationsOpen: false,
    }
  },
  methods: {
    ...mapActions(["logoutUser", "requestUser"]),
    ...mapMutations(["UPDATE_LANG"]),
    getLangFunction(option) {
      this.$i18n.locale = option;
      this.UPDATE_LANG(option);
      location.reload();
    },
    showBurgerMenu() {
      return this.isBurgerActive = !this.isBurgerActive,
          this.openMarsh = false
    },
    goToPersonal() {
      this.$router.push('/personal');
    },
    goToLogin() {
      this.$router.push('/login');
    },
    changeLanguage(option) {
      this.getLangFunction(option);
      this.isLangDropdownOpen = false;
    },
    logoutAcc() {
      this.logoutUser();
      this.$toaster.error(this.$t('toasters.successLogout'));
    },
    toggleLangDropdown() {
      this.isLangDropdownOpen = !this.isLangDropdownOpen;
      this.isProfileDropdownOpen = false;
      this.isNotificationsOpen = false;
    },
    toggleProfileDropdown() {
      this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
      this.isLangDropdownOpen = false;
      this.isNotificationsOpen = false;
    },
    closeLangDropdown(e) {
      const lang = this.$refs.langDropdown;
      if (!(e.target == lang || lang.contains(event.target))) {
        this.isLangDropdownOpen = false;
      }
    },
    closeProfileDropdown(e) {
      const profile = this.$refs.profileDropdown;
      if (!(e.target == profile || profile.contains(event.target))) {
        this.isProfileDropdownOpen = false;
      }
    },
    closeNotifDropdown(e) {
      const notif = this.$refs.notifDropdown;
      if (!(e.target == notif || notif.contains(event.target))) {
        this.isNotificationsOpen = false;
      }
    },
    async loadUserNotifications() {
      await this.$axios.get('/user-profile')
          .then(response => {
            this.userNotifications = response.data.userNotifications;
          })
          .catch(error => {
            console.error(error);
          });
    },
    toggleNotifications() {
      this.isNotificationsOpen = !this.isNotificationsOpen;
      this.isLangDropdownOpen = false;
    },
    goToNotificationsPage() {
      this.$router.push('/personal/notifications');
      this.isNotificationsOpen = false;
    },
  },
  computed: {
    ...mapGetters(["getLang", "getUser", "getAuth"]),
    isAuthenticated() {
      return this.$store.getters.getAuth;
    },
    userName() {
      return this.$store.getters.getUser ? this.$store.getters.getUser.name : '';
    },
  },
  created() {
    this.requestUser();
    this.loadUserNotifications();
  },
}
</script>

<style lang="scss" scoped>
.backHeader {
  background-color: $whiteText;
}

.headerD {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__top {
    display: flex;
    justify-content: space-between;
    &_left {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      gap: 25px;
      height: 100%;
      margin: 10px 0;
      &-subLogo {
        display: flex;
        align-items: flex-start;
        &-img {
          width: 100%;
          height: 50px;
        }
      }
      &-logo {
        width: 100%;
        height: 89px;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      position: relative;
      gap: 50px;
      &-lang {
        .dropdown-lang {
          position: relative;
          display: inline-block;
          cursor: pointer;
        }
        .dropdown-lang-content {
          display: block;
          position: absolute;
          right: 0;
          border-radius: 10px;
          background-color: white;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          z-index: 1;
          right: 0;
          margin-top: 20px;
          padding: 10px;
        }

        .dropdown-lang-content div {
          padding: 10px;
          cursor: pointer;
          &:hover {
            color: #9893DA;
          }
        }
      }
      &-profile {
        .dropdown-profile-content {
          display: block;
          position: absolute;
          right: 0;
          background-color: white;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          border-radius: 10px;
          z-index: 1;
          width: 264px;
          margin-top: 20px;
          padding: 10px;
          div {
            cursor: pointer;
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            &:hover {
              background-color: #9893DA;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

.mobile-lms {
  display: none;
}

.backHeaderMobile {
  left: 0;
  background-color: $whiteText;
  z-index: 997;
  right: 0;
}

.headerM {
  display: flex;
  flex-direction: column;
  &__top {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_right {
      &-burger {
        display: block;
        width: 45px;
        height: 24px;
        position: relative;
        cursor: pointer;
        z-index: 9;
        & span {
          transition: all .3s ease 0s;
          top: calc(50% - 2px);
          left: 0;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: $mainTextColor;
          border: 1.7px solid $mainTextColor;
          border-radius: 25px;

          &:first-child {
            top: 0;
            //border: 1.7px solid #000000;
          }

          &:last-child {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
    &-left {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      height: 100%;
      gap: 20px;
      &-subLogo {
        display: flex;
        align-items: flex-start;
        height: 100%;
        &-img {
          width: 100%;
          height: 40px;
        }
      }
      &-logo {
        width: 100%;
        height: 79px;
      }
    }
  }
  &__bot {
    transition: all 0.5s ease;
    display: none;
    opacity: 0;
    margin-top: 16px;
    flex-direction: column;
    gap: 9px;
    &-icons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      &-lang {
        .dropdown-lang {
          position: relative;
          display: inline-block;
          cursor: pointer;
        }
        .dropdown-lang-content {
          display: block;
          position: absolute;
          border-radius: 10px;
          background-color: white;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          margin-top: 20px;
          z-index: 1;
          right: -50px;
        }
        .dropdown-lang-content div {
          padding: 20px;
          cursor: pointer;
          &:hover {
            color: #9893DA;
          }
        }
      }
      &-profile {
        .dropdown-profile-content {
          display: block;
          position: absolute;
          right: 50px;
          background-color: white;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          border-radius: 10px;
          z-index: 1;
          margin-top: 20px;
          padding: 10px;
          div {
            cursor: pointer;
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            &:hover {
              background-color: #9893DA;
              border-radius: 10px;
            }
          }
        }
      }
      &-notification {
        position: relative;
      }
    }
    p {
      display: flex;
      align-items: center;
    }
    a {
      text-decoration: none;
      color: #001D5B;
    }
  }

  &__botactive {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 25px 35px;
    background: white;
    z-index: 1;
    opacity: 1;
    -webkit-animation: fadeIn-4225ec55 0.5s ease-in-out;
    animation: fadeIn-4225ec55 .5s ease-in-out;
  }
}

.burger-active {
  span {
    transform: scale(0);

    &:first-child {
      transform: rotate(-45deg);
      top: calc(50% - 1px);
    }

    &:last-child {
      transform: rotate(45deg);
      bottom: calc(50% - 3px);
    }
  }
}
.notification-dropdown {
  position: absolute;
  color: $mainTextColor;
  left: -135px;;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  margin-top: 10px;
  padding: 10px;
}

.notification-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background-color: #f5f5f5;
    border-radius: 10px;
  }
}

.notification-empty {
  padding: 10px;
  text-align: center;
  color: #999;
}
.notification-icon {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 2px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    @media screen and (max-width: $tablet) {
      background-color: #001D5B;
    }
  }
}


@media screen and (max-width: $tablet) {
  .desctop-lms {
    display: none;
  }
  .mobile-lms {
    display: flex;
    flex-direction: column;
  }
}
</style>
<template>
  <div class="calendar">
    <div class="calendar_container">
      <Calendar
          class="calendar_container-current"
          :min-date="new Date()"
          :columns="isMobile ? 1 : 2"
          locale="en"
          transparent
          borderless
          :attributes='attributes'
      >
        <template #day-popover="{ dayTitle, attributes}">
          <div class="calendar_event">
            <div class="calendar_event-date">
              {{ dayTitle }}
            </div>
            <ul>
              <li
                  v-for="{ key, customData } in attributes"
                  :key="key"
                  class="event_content"
              > <br>
                <div class="event_content-title">
                  <strong>{{ customData.title}}</strong>
                </div>
                {{ $t('events.eventType') }}: {{ customData.event_type }} <br>
                {{ $t('description') }}: {{ customData.description }}
              </li>
            </ul>
          </div>
        </template>
      </Calendar>
    </div>
  </div>
</template>

<script>
import { Calendar } from 'v-calendar';
import 'v-calendar/style.css';
import VTooltip from 'v-calendar'
import {mapGetters, mapMutations} from "vuex";
export default {
  props: ['events'],
  components: {
    Calendar,
    VTooltip
  },
  data() {
    return {
      date: new Date(),
      events: [],
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapMutations(["UPDATE_LANG"]),
    attributes() {
      const repeatedEvents = this.events.reduce((acc, event) => {
        if (event.is_event_daily === 1) {
          const start = new Date(event.date);
          const end = new Date(event.duration_date || event.date);

          const daysInMonth = 30;
          const dayInterval = 24 * 60 * 60 * 1000;
          for (let i = 0; i < daysInMonth; i++) {
            const currentDate = new Date(start.getTime() + i * dayInterval);
            acc.push({
              id: event.id + i,
              dates: currentDate,
              customData: event,
              highlight: {
                start: { fillMode: 'outline' },
                base: { fillMode: 'light' },
                end: { fillMode: 'outline' },
              },
              popover: {
                label: `${event.title} - ${event.description}(${event.event_type})`
              },
            });
          }
        } else {
          acc.push({
            id: event.id,
            dates: event.duration_date ? { start: new Date(event.date), end: new Date(event.duration_date) } : new Date(event.date),
            customData: event,
            highlight: {
              start: { fillMode: 'outline' },
              base: { fillMode: 'light' },
              end: { fillMode: 'outline' },
            },
            popover: {
              label: `${event.title} - ${event.description}(${event.event_type})`
            },
          });
        }
        return acc;
      }, []);

      return [...repeatedEvents];
    },
    isMobileScreen() {
      return window.innerWidth <= "1024";  // Измените значение согласно вашим требованиям
    },
  },
  watch: {
    isMobileScreen(value) {
      this.isMobile = value;
    },
  },
  methods: {
    fetchEvents() {
      this.$axios.get('/user-events',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
      )
          .then((response) => {
            this.events = response.data.events;
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  created() {
    this.fetchEvents();
    this.isMobile = this.isMobileScreen;
    window.addEventListener("resize", () => {
      this.isMobile = this.isMobileScreen;
    });
  },
};
</script>

<style lang="scss" scoped>
.calendar_container {
  display: flex;
  align-items: center;
  overflow: auto;
  @media screen and (max-width: $laptop) {
    flex-direction: column;
    gap: 30px;
    justify-content: center;
  }
}
</style>
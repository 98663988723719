<template>
  <div class="accpage">
    <div class="accpage__personal container">
      <div class="accpage__personal_dashboard" v-show="$route.name !== 'education-lesson'">
        <div
            class="accpage__personal_dashboard-mycourse"
            v-if="$route.path.startsWith('/personal/mycourses/')"
        >
          <EduMenu class="accpage__personal_dashboard-mycourse-edu"/>
        </div>

        <div class="accpage__personal_dashboard-nav">
          <NavMenu class="accpage__personal_dashboard-nav-menu"/>
        </div>

        <div
            class="accpage__personal_dashboard-info blocks"
            v-if="$route.path === '/personal'"
        >
          <div class="accpage__personal_dashboard-info-profile">
            <div class="accpage__personal_dashboard-info-profile-img">
              <img src="../../assets/icons/userimg.svg" alt="userimg">
            </div>
            <div v-if="isAuthenticated" class="accpage__personal_dashboard-info-profile-txt">
              <p class="semi-bold-title"> {{ getUser.name }} </p>
              <p class="regular-txt">{{ $t('personal.registerDate') }}: {{ getUser.created_at }}</p>
            </div>
          </div>
          <div class="accpage__personal_dashboard-info-profile">
            <div class="accpage__personal_dashboard-info-profile-img">
              <img src="../../assets/icons/coursebook.svg" alt="coursebook">
            </div>
            <div class="accpage__personal_dashboard-info-profile-txt">
              <p class="second-title">{{ $t('personal.myCourses') }}</p>
              <p class="bold-title">{{ getUser.courses_count }}</p>
            </div>
          </div>
          <div class="accpage__personal_dashboard-info-profile">
            <div class="accpage__personal_dashboard-info-profile-img">
              <img src="../../assets/icons/certificate.svg" alt="certificate">
            </div>
            <div class="accpage__personal_dashboard-info-profile-txt">
              <p class="second-title">{{ $t('personal.myCertificates') }}</p>
              <p class="bold-title">{{ getUser.certificates_count }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
          class="accpage__personal_courseinfo"
          v-if="$route.path === '/personal'"
      >
        <div class="accpage__personal_courseinfo-info blocks">
          <h2 class="main-title">{{ $t('personal.infoCourses') }}</h2>
          <div class="accpage__personal_courseinfo-info-progresstabs">
            <p
                class="regular-txt"
                @click="currentTab = 'all'"
                :class="{ activeTab: currentTab === 'all' }"
            >
              {{ $t('personal.all') }}
            </p>
            <p
                class="regular-txt"
                @click="currentTab = 'current'"
                :class="{ activeTab: currentTab === 'current' }"
            >
              {{ $t('personal.current') }}
            </p>
            <p
                class="regular-txt"
                @click="currentTab = 'scheduled'"
                :class="{ activeTab: currentTab === 'scheduled' }"
            >
              {{ $t('personal.planned') }}
            </p>
            <p
                class="regular-txt"
                @click="currentTab = 'finished'"
                :class="{ activeTab: currentTab === 'finished' }"
            >
              {{ $t('personal.completed') }}
            </p>
          </div>
          <div class="accpage__personal_courseinfo-info-progress">
            <div class="accpage__personal_courseinfo-info-progress-item">
              <CourseProgress
                  v-for="course in currentCourses"
                  :key="course.id"
                  :data="course"
                  :typeSpanDelete="true"
                  :currentTab="currentTab"
              />
            </div>
          </div>
        </div>

        <div class="accpage__personal_courseinfo-calendar blocks" v-if="$route.path === '/personal'">
          <h2 class="main-title">{{ $t('calendar') }}</h2>
          <CalendarView
              class="accpage__personal_courseinfo-calendar-view"
          />
        </div>
      </div>

      <router-view></router-view>

      <div class="accpage__personal_lessonMenu" v-if="$route.name === 'education-lesson'">
        <div class="accpage__personal_lessonMenu-nav">
          <LessonSideMenu class="accpage__personal_lessonMenu-nav-menu"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarView from "@/components/CalendarPersonal.vue";
import CourseProgress from "@/components/CourseProgress.vue";
import NavMenu from "@/components/UI/NavigationMenu.vue";
import EduMenu from "@/components/UI/EducationMenu.vue"
import LessonSideMenu from "@/components/UI/LessonSideMenu.vue";
import {mapGetters} from "vuex";
import MyCourseProgress from "@/components/CourseProgress.vue";
export default {
  components: {MyCourseProgress, CourseProgress, CalendarView, NavMenu, EduMenu, LessonSideMenu },
  data() {
    return {
      currentTab: 'all',
    };
  },
  computed: {
    ...mapGetters(["getLang", "getUser", "getAuth", "getUserCourses"]),
    currentCourses() {
      return this.getUserCourses[this.currentTab]
    },
    isAuthenticated() {
      return this.$store.getters.getAuth;
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.accpage__personal {
  display: flex;
  padding-top: 40px;
  padding-bottom: 120px;
  color: $mainTextColor;
  position: relative;
  gap: 20px;
  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }
  &_dashboard {
    display: flex;
    flex-direction: column;
    min-width: 25%;
    gap: 20px;
    @media screen and (max-width: $laptop) {
      min-width: 35%;
    }

    &-nav {
      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 30px 30px;
      gap: 30px;
      &-profile {
        display: flex;
        align-items: center;
        gap: 20px;
        img {
          width: 25px;
          height: 25px;
        }
        &-img {
          padding: 20px;
          border: 1.6px solid #062060;
          border-radius: 50%;
        }
        &-txt {
          .semi-bold-title {
            font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
          }
          .second-title {
            font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
          }
          .bold-title {
            font-size: 30px;
          }
        }
      }
    }
  }

  &_lessonMenu {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    align-self: flex-start;
    width: 40%;
    gap: 20px;
    margin-left: auto;
    @media screen and (max-width: $laptop) {
      width: 35%;
    }
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
    &-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: sticky;
      &-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
  &_courseinfo {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    width: 100%;
    .main-title {
      font-size: 30px;
      @media screen and (max-width: $tablet) {
        text-align: center;
      }
    }
    &-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      width: 100%;
      padding: 40px 40px 160px 40px;
      position: relative;
      @media screen and (max-width: $tablet) {
        flex-wrap: wrap;
      }
      &-progress {
        display: flex;
        align-items: center;
        width: 100%;
        &-item {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          gap: 20px;
        }
      }
      &-progresstabs {
        display: flex;
        align-items: center;
        overflow-x: auto;
        margin-top: 40px;
        width: 100%;
        gap: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 1.3369)), 40px);
        .regular-txt {
          cursor: pointer;
          text-align: center;
          font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
        }
      }
    }

    &-calendar {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 35px 163px;
      @media screen and (max-width: $tablet) {
        padding: 35px 0;
      }
      &-view {
        padding: 50px 16px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.activeTab {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #9893DA;
  color: #9893DA;
}
</style>
<template>
    <div class="testResult">
        <div class="testResult__content">
          <div class="testResult__content-title main-title blocks-title">
            <h1 class="testResult__content-title-txt">{{ $t('tests.moduleTest') }}</h1>
            <div class="testResult__content-title-currpage current-page">
              <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
              <p>/</p>
              <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
              <p>/</p>
              <p>{{ $t('tests.ratingModuleScore') }}</p>
            </div>
          </div>

          <div class="testResult__content_info blocks">
            <div class="testResult__content_info-back">
              <button @click="backToMain">
                <img src="@/assets/icons/back-btn.svg" alt="back-btn">
                <p class="main-title">{{ $t('buttons.back') }}</p>
              </button>
            </div>
            <div class="testResult__content_info_block">
              <div class="testResult__content_info_block-title">
                <h3 class="main-title">{{ $t('tests.ratingModuleScore') }}</h3>
              </div>
              <div class="testResult__content_info_block_testing">
                <div 
                    class="testResult__content_info_block_testing-item" 
                    v-for="(result, idx) in userTests" 
                    :key="idx"
                >
                  <h3 class="main-title">№{{ idx + 1 }}. {{ result.title }}</h3>
                  <div 
                    class="testResult__content_info_block_testing-item-answers"
                  >
                    <div 
                        class="testResult__content_info_block_testing-item-answers-text second-title"
                        v-for="(answer, idx) in result.answers" 
                        :key="idx"
                    >
                        <p>{{ idx + 1 }}. {{ answer.title }}</p>
                        <span v-if="result.user_answer_ids.includes(answer.id)" class="user">
                          {{ $t('tests.yourAnswer') }}
                        </span>
                    </div>
                  </div>
                  <div 
                    class="testResult__content_info_block_testing-item-correct"
                    v-if="result.is_correct === 1"
                  >
                    <p class="second-title">
                        {{ $t('tests.correctAnswerTitle') }}
                    </p>
                    <div class="second-title" v-if="result.explanation" v-html="result.explanation"></div>
                  </div>
                  <div 
                    class="testResult__content_info_block_testing-item-incorrect"
                    v-else
                  >
                    <p class="second-title" v-for="(answer, answerIdx) in result.answers" :key="answerIdx">
                        <span v-if="result.correct_answers_ids.includes(answer.id)">{{ $t('tests.incorrectAnswerTitle') }} {{ answer.title }}</span>
                    </p>
                    <div class="second-title" v-if="result.explanation" v-html="result.explanation"></div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
</template>

<script>
    export default {
        data() {
            return {
                userTests: [],
                correctId: null,
            };
        },
        methods: {
            fetchTestingResult(educationId, moduleId, resultId) {
                this.$axios
                .get(`courses/${educationId}/userTestResults/${resultId}`)
                .then((response) => {
                    this.userTests = response.data.userTests;
                })
                .catch((error) => {
                    console.error(error);
                });
            },
            backToMain() {
              const educationId = this.$route.params.educationId;
              this.$router.push({ name: 'education', params: { educationId: educationId } });
            },
        },
        created() {
            const educationId = this.$route.params.educationId;
            const moduleId = this.$route.params.moduleId;
            const resultId = this.$route.params.resultId;
            this.fetchTestingResult(educationId, moduleId, resultId);
        }
    }
</script>

<style lang="scss" scoped>
.testResult {
    width: 100%;
    color: $mainTextColor;
    &__content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 40px;
        &-back {
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          width: 100%;
          button {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        &_block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
          width: 100%;
          &-title {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            h3 {
                font-size: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 0.6684)), 30px);
            }
          }
          &_testing {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
            width: 100%;
            &-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
                width: 100%;
                h3 {
                    font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
                }
                &-answers {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 20px;
                    gap: 20px;
                    width: 100%;
                    &-text {
                        font-size: clamp(14px, calc(0.875rem + ((1vw - 7.68px) * 0.2232)), 18px);
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 5px;
                        .user {
                          padding: 5px;
                          background-color: #72727E;
                          border-radius: 10px;
                          color: $whiteText;
                        }
                    }
                }
                &-correct {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px 20px;
                    background-color: #01FF2A;
                    border-radius: 10px;
                    gap: 5px;
                    width: 100%;
                    font-size: clamp(14px, calc(0.875rem + ((1vw - 7.68px) * 0.2232)), 18px);
                }
                &-incorrect {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px 20px;
                    background-color: #f72a2a;
                    color: #FFFFFF;
                    border-radius: 10px;
                    gap: 5px;
                    width: 100%;
                    font-size: clamp(14px, calc(0.875rem + ((1vw - 7.68px) * 0.2232)), 18px);
              }
            }
          }
        }
      }
    }
  }
</style>
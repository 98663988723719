<template>
  <div class="mycourses">
    <div class="mycourses__content">
      <div class="mycourses__content-title main-title blocks-title">
        <h1 class="mycourses__content-title-txt">{{ $t('personal.myCourses') }}</h1>
        <div class="mycourses__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p>{{ $t('personal.myCourses') }}</p>
        </div>
      </div>
      <div class="mycourses__content-progress blocks" v-if="mycourses">
        <div
            class="mycourses__content-progress-item blocks"
            v-for="course in mycourses"
            :key="course.id"
        >
          <MyCourseProgress
              class="mycourses__content-progress-item-block"
              :typeButton="true"
              :data="course"
          />
        </div>
        <div class="mycourses__content-noprogress blocks" v-if="mycourses == 0">
          <h1 class="main-title">{{ $t('errors.noCourses') }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyCourseProgress from "@/components/CourseProgress.vue";
import CourseProgress from "@/components/CourseProgress.vue";
import {mapActions} from "vuex";

export default {
  components: {
    CourseProgress,
    MyCourseProgress,
  },
  data() {
    return {
      mycourses: []
    };
  },
  methods: {
    ...mapActions(["logoutUser"]),
    logoutSystem() {
      this.logoutUser();
      this.$router.push("/login");
    },
  },
  created() {
    this.$axios.
    get(`user-courses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then(res => {
          this.mycourses = res.data.courses;
        })
        .catch(err => {
          this.$toaster.error(this.$t('toasters.sessionTimeout'));
          this.logoutSystem();
        });
  }
};
</script>

<style lang="scss" scoped>
.mycourses {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-progress {
      padding: 0 40px 230px 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      &-item {
        flex-basis: calc(50% - 20px);
      }
      @media screen and (max-width: $mobile) {
        flex-direction: column;
        align-items: center;
      }
    }
    &-noprogress {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 40px;
      justify-content: center;
      h1 {
        text-align: center;
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
        color: #CE0000;
      }
    }
  }
}
</style>
<template>
  <div class="analytics">
    <div class="analytics__content">
      <div class="analytics__content-title main-title blocks">
        <h1 class="analytics__content-title-txt">Аналитика</h1>
        <div class="analytics__content-title-currpage">
          <p>{{ $t('profile') }} / Аналитика</p>
        </div>
      </div>
      <div class="analytics__content-table blocks">
        <div class="analytics__content-table-header blocks" style="position: relative;">
          <p>Список всех участников</p>
          <input class="search" v-model="search" placeholder="Поиск" @input="searchText" />
          <div class="res-search" v-if="isSearch">
            <div class="courses">
              <div
                  @click="goToItem(item)"
                  data-aos="fade-right"
                  data-aos-offset="0"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="500" class="courses__items"
                  v-for="(item, idx) in searchResults" :key="idx"
              >
                <img src="@/assets/icons/search-icon.svg" alt="search-icon" />
                <div class="courses__items-text">
                  <span class="scale-text">{{ item.name }}</span>
                  <p v-html="item.flip_box_content" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <table class="custom-table">
          <thead>
          <tr>
            <th class="regular-txt">#</th>
            <th class="regular-txt">ФИО</th>
            <th class="regular-txt">Курс</th>
            <th class="regular-txt">%Прохождения</th>
            <th class="regular-txt">Средняя оценка</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in tableData" :key="index">
            <td class="regular-txt">{{ index + 1 }}</td>
            <td class="regular-txt">{{ row.name }}</td>
            <td class="regular-txt">{{ row.course }}</td>
            <td class="regular-txt">{{ row.progress }}%</td>
            <td class="regular-txt">{{ row.averageGrade }}</td>
          </tr>
          </tbody>
        </table>

        <div class="analytics__content-table-pages">
          <div class="analytics__content-table-pages-pagination">
            <button
                class="analytics__content-table-pages-pagination-arrow"
                @click="goToPage(currentPage - 1)"
                :disabled="currentPage === 1"
            >
              &lt;
            </button>
            <button
                class="analytics__content-table-pages-pagination-item"
            >
              1
            </button>
            <button
                class="analytics__content-table-pages-pagination-arrow"
                @click="goToPage(currentPage + 1)"
                :disabled="currentPage === totalPageCount"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>

      <div class="analytics__content-charts blocks">
        <Line :data="chartData" :options="options" />
        <div class="analytics__content-charts-indicators">
          <p class="analytics__content-charts-indicators-txt main-title">
            За этот месяц вы провели:
            <span>67 уроков</span>
          </p>
          <p class="analytics__content-charts-indicators-txt main-title">
            Процент посещаемости на ваших уроках:
            <span>87%</span>
          </p>
        </div>
      </div>

      <div class="analytics__content-limit blocks">
        <div class="analytics__content-limit-message">
          <p class="analytics__content-limit-message-border">{{ $t('buttons.limit') }}</p>
          <p class="analytics__content-limit-message-txt">У вас нет прав на просмотр этой страницы</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
  components: { Line },
  data: () => ({
    tableData: [],
    chartData: {
      labels: [ 'January', 'February', 'March'],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: 'transparent',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointHitRadius: 30,
          pointBorderWidth: 2,
          fill: 'origin',
          borderColor: '#efd455',
          tension: 0,
          data: [40, 20, 12]
        },
        {
          label: 'Data Two',
          backgroundColor: 'transparent',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointHitRadius: 30,
          pointBorderWidth: 2,
          borderColor: '#f17234',
          data: [43, 50, 55, 32, 76]
        }
      ],
    },
    options: {
    },
  }),
  methods: {
    generateRandomData() {
      for (let i = 0; i < 10; i++) {
        const randomName = names[Math.floor(Math.random() * names.length)];
        const randomCourse = Math.floor(Math.random() * 4) + 1;
        const randomProgress = Math.floor(Math.random() * 100);
        const randomAverageGrade = (Math.random() * 5).toFixed(2);
        this.tableData.push({
          name: randomName,
          course: randomCourse,
          progress: randomProgress,
          averageGrade: randomAverageGrade
        });
      }
    }
  },
  mounted() {
    this.generateRandomData();
  }
};
</script>

<style lang="scss" scoped>
.analytics {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-table {
      padding: 40px;
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          padding: 10px;
          border: 1px solid #CBD5E0;
          border-radius: 4px;
          color: #CBD5E0;
        }
        p {
          font-size: 20px;
        }
      }
      &-pages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 50px;
        position: relative;
        &-pagination {
          &-item {
            padding: 7px 13px;
            border: 1px solid #CBD5E0;
            border-radius: 50%;
            background: $backColor;
            cursor: pointer;
            margin: 0 2px;
            color: #ffffff;
            &:hover {
              border: 1px solid #ffa204;
            }
            &.active-page {
              border: 1px solid #ffa204;
            }
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
          &-arrow {
            padding: 0 25px 0 25px;
            color: #000000;
            font-size: 20px;
          }
        }
      }
      .custom-table {
        width: 100%;
        margin-top: 20px;
        border-collapse: collapse;
        thead {
          width: 100%;
          th {
            padding: 15px;
            background: #EDF2F7;
            text-align: start;
          }
        }
        tbody {
          td {
            padding: 15px;
            text-align: start;
            border-top: 1px solid #E2E8F0;
          }
        }
      }
    }

    &-limit {
      display: flex;
      justify-content: center;
      &-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        padding: 100px;
        color: #CE0000;
        &-border {
          border: 1px solid #CE0000;
          border-radius: 10px;
          padding: 5px 20px;
        }
      }
    }

    &-charts {
      display: flex;
      flex-direction: column;
      padding: 40px;
      gap: 40px;
      &-indicators {
        display: flex;
        flex-direction: column;
        gap: 40px;
        &-txt {
          font-size: 24px;
          span {
            color: #9893DA;
          }
        }
      }
    }
  }
}
.res-search {
  position: absolute;
  top: 120%;
  left: 0;
  width: 150%;
  padding: 15px;
}
.courses {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  &__items {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 15px;
    background: rgba(1, 25, 122, 0.8);
    border: 1px solid #ffa204;
    img {
      width: 20px;
      max-height: 20px;
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        color: white;
        font-size: 12px;
        overflow-y: auto;
        text-decoration: none !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        color: #ffa204;
        font-size: 15px;
        text-decoration: none !important;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<template>
  <div class="testing">
    <div class="testing__content">
      <div class="testing__content-title main-title blocks-title">
        <h1 class="testing__content-title-txt">{{ $t('tests.moduleTest') }}</h1>
        <div class="testing__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('tests.moduleTest') }}</p>
        </div>
      </div>

      <div class="testing__content_test blocks" v-if="testData && !isSubmitting">
        <div class="testing__content_test-back">
          <button @click="$router.go(-1)">
            <img src="@/assets/icons/back-btn.svg" alt="back-btn">
            <p class="main-title">{{ $t('buttons.back') }}</p>
          </button>
        </div>
        <h2>{{ testData.title }}</h2>
        <div
            class="testing__content_test-questions"
            v-for="(question, index) in testData.questions"
            :key="question.id"
            v-show="currentIndex === index"
        >
          <h2 class="main-title">{{ index + 1 }}. {{ question.title }}</h2>

          <div class="testing__content_test-questions-answers">
            <div
                class="testing__content_test-questions-answers-btn"
                v-for="(answer, idx) in question.answers"
                :key="answer.id"
            >
              <button
                  :class="{ 'selected': userAnswers[question.id]?.includes(answer.id) }"
                  @click="selectAnswer(question.id, answer.id)"
              >
                {{ idx + 1 }}. {{ answer.title }}
              </button>
            </div>
          </div>
        </div>

        <div class="testing__content_test-navigation">
          <div class="testing__content_test-navigation-prev">
            <button
                @click="prevQuestion"
                :disabled="currentIndex === 0"
                v-show="currentIndex > 0"
                class="testing__content_test-navigation-prev-btn"
            >
              <img src="@/assets/icons/arrow-left.svg" alt="arrow-left">
              <p class="main-title">{{ $t('buttons.previous') }}</p>
            </button>
          </div>
          <div class="testing__content_test-navigation-submit">
            <button
                class=" testing__content_test-navigation-submit-btn main-button"
                @click="submitTest"
                v-if="isLastPage"
                :disabled="!userAnswers[testData.questions[currentIndex]?.id]"
            >
              {{ $t('buttons.submit') }}
            </button>
          </div>
          <div class="testing__content_test-navigation-next">
            <button
                @click="nextQuestion"
                :disabled="!userAnswers[testData.questions[currentIndex]?.id]"
                v-show="!isLastPage"
                class="testing__content_test-navigation-next-btn"
            >
              <p class="main-title">{{ $t('buttons.next') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
        </div>
      </div>
      <div class="testing__content_test-result blocks" v-if="testResult && isSubmitting">
        <div class="testing__content_test-result-back">
          <button @click="backToMain">
            <img src="@/assets/icons/back-btn.svg" alt="back-btn">
            <p class="main-title">{{ $t('buttons.back') }}</p>
          </button>
        </div>
        <div class="testing__content_test-result-info">
          <h2 class="semi-bold-title">{{ testData.title }}</h2>
          <p class="main-title">{{ $t('tests.youScored') }} {{ testResult.score }} {{ $t('tests.gradesScore') }}</p>
          <p class="main-title">{{ $t('tests.questionsCount') }} {{ testResult.questions_count }}</p>
          <p class="main-title">{{ $t('tests.correctAnswersCount') }} {{ testResult.is_correct_count }}</p>
          <p class="main-title">{{ $t('tests.incorrectAnswersCount') }} {{ testResult.is_incorrect_count }}</p>
          <button class="testing__content_test-result-info-btn" @click="viewTestingResult()">
            <p class="main-title">{{ $t('buttons.more') }}</p>
          </button>
        </div>
        <div
            class="testing__content_test-result-nextLesson"
            v-if="testResult.score > 50 && nextLessonData !== null"
        >
          <button class="testing__content_test-result-nextLesson-btn" @click="goToNextLesson">
            <p class="main-title">{{ $t('education.nextLesson') }}</p>
            <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
          </button>
        </div>
        <div
            class="testing__content_test-result-nextLesson"
            v-if="finalTestData.status === 1"
        >
          <button class="testing__content_test-result-nextLesson-finalbtn" @click="finalTestModule">
            <p class="main-title">{{ $t('education.finalTesting') }}</p>
            <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store";

export default {
  components: {
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isTestFinished()) {
      this.$toaster.error(this.$t('errors.userTestLeave'));
      next(false);
    } else {
      next();
    }
  },
  data() {
    return {
      userAnswers: {},
      testData: null,
      currentIndex: 0,
      isSubmitting: false,
      nextLessonData: null,
      testResult: [],
      finalTestData: [],
    };
  },
  computed: {
    isLastPage() {
      return this.currentIndex === this.testData.questions.length - 1;
    },
  },
  methods: {
    selectAnswer(questionId, answerId) {
      if (!questionId || !answerId || !this.userAnswers) {
        this.$toaster.error(this.$t('errors.userNotAnswers'));
        return;
      }

      if (!this.userAnswers[questionId]) {
        this.userAnswers[questionId] = [];
      }

      const answerIndex = this.userAnswers[questionId].indexOf(answerId);

      if (answerIndex === -1) {
        // Если ответ еще не выбран, добавить его в массив
        this.userAnswers[questionId].push(answerId);
      } else {
        // Если ответ уже выбран, удалить его из массива
        this.userAnswers[questionId].splice(answerIndex, 1);
      }
      this.saveUserAnswersToLocalStorage();
    },
    isTestFinished() {
      return this.isLastPage && this.isSubmitting;
    },
    nextQuestion() {
      if (!this.userAnswers[this.testData.questions[this.currentIndex]?.id] ||
          this.userAnswers[this.testData.questions[this.currentIndex]?.id].length === 0) {
        this.$toaster.error(this.$t('errors.userNotAnswers'));
        return;
      }

      if (this.currentIndex < this.testData.questions.length - 1) {
        this.currentIndex++;
        this.saveCurrentPageToLocalStorage();
      }
    },
    prevQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      this.saveCurrentPageToLocalStorage();
    },
    backToMain() {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education', params: { educationId: educationId } });
    },
    submitTest() {
      const educationId = this.$route.params.educationId;
      const moduleId = this.$route.params.moduleId;
      const testId = this.$route.params.testId;
      this.clearLocalStorage();
      const answers = [];
      this.isSubmitting = true;
      if (!this.userAnswers[this.testData.questions[this.currentIndex]?.id]) {
        this.$toaster.error(this.$t('errors.userNotAnswersLastQuestion'));
        return;
      }
      for (const questionId in this.userAnswers) {
        if (this.userAnswers.hasOwnProperty(questionId)) {
          const answerId = this.userAnswers[questionId];
          answers.push({ question_id: questionId, answer_id: answerId });
        }
      }
      const postData = {
        answers: answers,
      };

      this.$axios
          .post(`courses/${educationId}/modules/${moduleId}/tests/${testId}/finished`, postData)
          .then((response) => {
            this.$toaster.success(this.$t('toasters.successSubmitTest'));
            this.testResult = response.data.testResult;
            this.finalTestData = response.data.course.courseTest;
            this.isSubmitting = true;
            this.nextLessonData = response.data.nextLesson;
          })
          .catch((error) => {
            console.error(error);
            this.isSubmitting = true;
          });
    },
    async goToNextLesson() {
      if (!this.nextLessonData) {
        return;
      }

      const educationId = this.$route.params.educationId;
      const nextLessonId = this.nextLessonData.id;
      const nextLessonUrl = `courses/${educationId}/lessons/${nextLessonId}`;
      const nextLessonResponse = await this.$axios.get(nextLessonUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const nextLessonData = nextLessonResponse.data.lesson;

      this.$router.push({
        name: 'education-lesson',
        params: {
          educationId,
          lessonId: nextLessonId,
        },
      });
    },
    async finalTestModule() {
      const educationId = this.$route.params.educationId;
      if (this.finalTestData.status === 1) {
        this.$router.push({ name: 'education-final-test', params: { educationId, finalTestId: this.finalTestData.id } });
      }
    },
    viewTestingResult() {
      const educationId = this.$route.params.educationId;
      const resultId = this.testResult.id;
      this.$router.push({ name: 'education-testing-result', params: { educationId: educationId, resultId: resultId } });
    },
    saveUserAnswersToLocalStorage() {
      localStorage.setItem('userAnswers', JSON.stringify(this.userAnswers));
    },

    saveCurrentPageToLocalStorage() {
      localStorage.setItem('currentPage', this.currentIndex.toString());
    },

    clearLocalStorage() {
      localStorage.removeItem('userAnswers');
      localStorage.removeItem('currentPage');
    },

    loadUserAnswersFromLocalStorage() {
      const storedAnswers = localStorage.getItem('userAnswers');
      if (storedAnswers) {
        this.userAnswers = JSON.parse(storedAnswers);
      }
    },

    loadCurrentPageFromLocalStorage() {
      const storedPage = localStorage.getItem('currentPage');
      if (storedPage) {
        this.currentIndex = parseInt(storedPage, 10);
      }
    },
  },
  created() {
    this.loadUserAnswersFromLocalStorage();
    this.loadCurrentPageFromLocalStorage();
    this.testData = store.state.testData;
  },
};
</script>

<style lang="scss" scoped>
.testing {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_test {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      padding: 40px;
      &-back {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        button {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      h2 {
        text-align: center;
      }
      &-questions {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px;
        gap: 80px;
        background: #F9F9F9;
        border-radius: 10px;
        h2 {
          font-size: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 0.6684)), 30px);
        }
        &-answers {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          width: 100%;
          &-btn {
            flex-basis: calc(50% - 20px);
            button {
              width: 100%;
              background-color: #02AEF080;
              padding: 20px 40px;
              border-radius: 10px;
              height: 100%;
            }
            @media screen and (max-width: $tablet) {
              flex-basis: 100%;
            }
          }
          @media screen and (max-width: $tablet) {
            width: auto;
          }
        }
      }
      &-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        width: 100%;
        &-prev {
          display: flex;
          width: 33%;
          align-items: start;
          justify-content: start;
          &-btn {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        &-next {
          display: flex;
          width: 33%;
          align-items: end;
          justify-content: end;
          &-btn {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        &-submit {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33%;
        }
      }
      &-result {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 40px;
       &-info {
         display: flex;
         flex-direction: column;
         align-items: center;
         padding: 80px 0;
         gap: 20px;
         background: #F9F9F9;
         border-radius: 10px;
         h2 {
           font-size: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 0.6684)), 30px);
         }
         p {
           font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
         }
         &-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: underline;
            width: auto;
          }
       }
        &-nextLesson {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          width: 100%;
          &-btn {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          &-finalbtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 15px;
          }
        }
        &-back {
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          width: 100%;
          button {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
  .selected {
    background-color: #02AEF0;
    color: #fff;
  }
}
</style>
<template>
  <div class="moduletest">
    <div class="moduletest__content">
      <div class="moduletest__content-title main-title blocks-title">
        <h1 class="moduletest__content-title-txt">{{ $t('tests.moduleTest') }}</h1>
        <div class="moduletest__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ $t('tests.moduleTest') }}</p>
        </div>
      </div>

      <div class="moduletest__content_info blocks">
        <div class="moduletest__content_info-back">
          <button @click="backToMain">
            <img src="@/assets/icons/back-btn.svg" alt="back-btn">
            <p class="main-title">{{ $t('buttons.back') }}</p>
          </button>
        </div>
        <Loader v-if="loading"/>
        <h2 class="semi-bold-title">{{ test.title }}</h2>
        <div
            class="moduletest__content_info-attempts"
            v-if="test.startTest === 1"
        >
          <p class="second-title">{{ $t('tests.attemptsCount') }} {{ test.chance }}</p>
          <div
              class="moduletest__content_info-attempts-lastresult"
              v-if="lastResult"
          >
            <p class="second-title">{{ $t('tests.lastScore') }} {{ lastResult.score }}</p>
          </div>
          <div
              class="moduletest__content_info-attempts-lastresult"
              v-else
          >
            <p class="second-title">{{ $t('tests.lastScore') }} {{ $t('tests.notPassTest') }}</p>
          </div>
          <div
              class="moduletest__content_info-attempts-testingresult"
              v-if="lastTestingResult && lastTestingResult.length > 0"
          >
            <div
                class="moduletest__content_info-attempts-testingResult"
                v-for="(result, idx) in lastTestingResult"
                :key="idx"
            >
              <p class="second-title">{{ $t('tests.questionsCount') }} {{ result.questions_count }}</p>
              <p class="second-title">{{ $t('tests.correctAnswersCount') }} {{ result.is_correct_count }}</p>
              <p class="second-title">{{ $t('tests.incorrectAnswersCount') }} {{ result.is_incorrect_count }}</p>
              <button class="moduletest__content_info-attempts-testingResult-btn" @click="viewTestingResult(result.id)">
                <p class="main-title">{{ $t('buttons.more') }}</p>
              </button>
            </div>
          </div>
          <p class="second-title">{{ $t('tests.warningTest') }}</p>
          <div class="moduletest__content_info-attempts-btn">
            <button class="main-button" @click="startTest">{{ $t('buttons.startTest') }}</button>
          </div>
          <div
              class="moduletest__content_info-attempts-nextLesson"
              v-if="lastResult && lastResult.score > 70"
          >
            <button @click="nextLesson">
              <p class="main-title">{{ $t('education.nextLesson') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
          <div
              class="moduletest__content_info-attempts-nextLesson"
              v-if="finalTestData.status === 1"
          >
            <button class="next-btn" @click="goToFinalTestModule">
              <p class="main-title">{{ $t('education.finalTesting') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
        </div>
        <div
            class="moduletest__content_info-noAttempts"
            v-else
        >
          <div
              class="moduletest__content_info-noAttempts-lastResult"
              v-if="lastResult"
          >
            <span class="main-title">{{ $t('tests.lastScore') }} {{ lastResult.score }}</span>
          </div>
          <div
              class="moduletest__content_info-noAttempts-testingresult"
              v-if="lastTestingResult && lastTestingResult.length > 0"
          >
            <div
                class="moduletest__content_info-noAttempts-testingResult"
                v-for="(result, idx) in lastTestingResult"
                :key="idx"
            >
              <p class="second-title">{{ $t('tests.questionsCount') }} {{ result.questions_count }}</p>
              <p class="second-title">{{ $t('tests.correctAnswersCount') }} {{ result.is_correct_count }}</p>
              <p class="second-title">{{ $t('tests.incorrectAnswersCount') }} {{ result.is_incorrect_count }}</p>
              <p class="main-title">{{ $t('tests.youScored') }} {{ result.score }}</p>
              <button class="moduletest__content_info-noAttempts-testingResult-btn" @click="viewTestingResult(result.id)">
                <p class="main-title">{{ $t('buttons.more') }}</p>
              </button>
            </div>
          </div>
          <p class="moduletest__content_info-noAttempts-message second-title" v-if="test.chance === 0">{{ $t('errors.noAttempts') }}</p>
          <div
              class="moduletest__content_info-noAttempts-nextLesson"
              v-if="lastResult && lastResult.score > 70 && nextLessonButton === true"
          >
            <button @click="nextLesson">
              <p class="main-title">{{ $t('education.nextLesson') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
          <div
               class="moduletest__content_info-noAttempts-nextLesson"
               v-if="finalTestData.status === 1"
          >
            <button class="next-btn" @click="goToFinalTestModule">
              <p class="main-title">{{ $t('education.finalTesting') }}</p>
              <img src="@/assets/icons/arrow-right.svg" alt="arrow-right">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Loader from '../UI/Loader.vue';

export default {
  components: {
    Loader
  },
  data() {
    return {
      test: {},
      testTitle: '',
      lastResult: null,
      lastTestingResult: [],
      finalTestData: [],
      nextLessonButton: false,
      nextLessonId: null,
      loading: false,
    };
  },
  methods: {
    startTest() {
      const educationId = this.$route.params.educationId;
      const moduleId = this.$route.params.moduleId;
      const testId = this.$route.params.testId;
      this.$axios
          .get(`courses/${educationId}/modules/${moduleId}/tests/${testId}/start`)
          .then((response) => {
            const testData = response.data.test;
            store.commit('SET_TEST_DATA', testData);
            this.$router.push({
              name: 'education-start-test',
              params: {
                educationId,
                moduleId,
                testId,
              },
            });
          })
          .catch((error) => {
            console.error(error);
          });
    },
    fetchModuleTestData(educationId, moduleId, testId) {
      this.loading = true;
      this.$axios
          .get(`courses/${educationId}/modules/${moduleId}/tests/${testId}`)
          .then((response) => {
            this.test = response.data.test;
            this.finalTestData = response.data.course.courseTest;
            this.lastResult = response.data.lastTestResult;
            this.lastTestingResult = response.data.lastTests;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false; // Завершение загрузки
          });
    },
    async fetchModuleData() {
      const educationId = this.$route.params.educationId;
      const moduleId = this.$route.params.moduleId;
      try {
        const response = await this.$axios.get(`courses/${educationId}/modules`);
        const modules = response.data.modules;
        const currentModuleIndex = modules.findIndex((module) => module.id === Number(moduleId));
        const nextModule = modules[currentModuleIndex + 1];
        this.nextLessonId = nextModule.lessons[0].id;

        if (this.nextLessonId) {
          this.nextLessonButton = true;
        } else {
          this.nextLessonButton = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async nextLesson() {
      await this.fetchModuleData();
      const educationId = this.$route.params.educationId;
      if (this.nextLessonId) {
        this.$router.push({
          name: 'education-lesson',
          params: { educationId: educationId, lessonId: this.nextLessonId },
        });
      }
    },
    async goToFinalTestModule() {
      const educationId = this.$route.params.educationId;
      if (this.finalTestData.status === 1) {
        this.$router.push({ name: 'education-final-test', params: { educationId, finalTestId: this.finalTestData.id } });
      }
    },
    viewTestingResult(resultId) {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education-testing-result', params: { educationId: educationId, resultId: resultId } });
    },
    backToMain() {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education', params: { educationId: educationId } });
    },
  },
  created() {
    const educationId = this.$route.params.educationId;
    const moduleId = this.$route.params.moduleId;
    const testId = this.$route.params.testId;
    this.fetchModuleTestData(educationId, moduleId, testId);
    this.fetchModuleData();
  },
};
</script>

<style lang="scss" scoped>
.moduletest {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding: 40px;
      &-back {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        button {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      &-attempts {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 20px;
        width: 100%;
        &-btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          button {
            width: 50%;
            @media screen and (max-width: $mobile) {
              width: 100%;
            }
          }
        }
        &-nextLesson {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          width: 100%;
          button {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        &-testingResult {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-bottom: 20px;
          gap: 20px;
          &-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: underline;
            width: auto;
          }
        }
      }
      &-noAttempts {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 20px;
        width: 100%;
        &-message {
          color: #CE0000;
        }
        &-nextLesson {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          width: 100%;
          button {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        &-testingResult {
          display: flex;
          flex-direction: column;
          text-align: center;
          background-color: #F9F9F9;
          border-radius: 10px;
          padding: 20px;
          margin: 20px 0;
          gap: 20px;
          &-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: underline;
            width: auto;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="footer">
    <div class="footer__main container">
      <div class="footer__main_left">
        <div class="footer__main_left-logo">
          <div class="footer__main_left-logo-img">
            <img style="cursor: pointer;" @click="$router.push('/')" src="@/assets/images/png/aqparattyqsana.png" alt="lms-logo"/>
          </div>
<!--          <div class="footer__main_left-logo-subLogo">-->
<!--            <img src="@/assets/images/png/KAP-technology.png" alt="subLogo" class="footer__main_left-logo-subLogo-img">-->
<!--          </div>-->
<!--          <p class="footer__main_left-logo-text">{{ $t('footerLink') }}<a href="https://studionomad.kz/"> Studio Nomad</a></p>-->
        </div>
        <div
            class="footer__main_left-info"
        >
          <div class="footer__main_left-info-img">
            <div class="footer__main_left-info-img-contacts">
              <img :src="emailIcon" alt="phone"/>
              <p v-html="emailLink"></p>
            </div>
            <div class="footer__main_left-info-img-contacts">
              <img :src="phoneIcon" alt="phone"/>
              <p v-html="phoneLink"></p>
            </div>
          </div>
          <p class="footer__main_left-info-text light-txt">
            {{ contactRule }}
          </p>
        </div>
      </div>
      <div class="footer__main_right">
        <div class="footer__main_right-socmedia">
          <div
              class="footer__main_right-socmedia-links"
              v-for="(item, index) in socials"
              :key="index"
          >
            <a :href="item.link">
              <img :src="item.image" alt="social_image"/>
            </a>
          </div>
<!--          <div class="footer__main_right-socmedia-btn">-->
<!--            <button-->
<!--                v-if="!getAuth"-->
<!--                @click="$router.push('/login')"-->
<!--            >-->
<!--              Войти-->
<!--            </button>-->
<!--          </div>-->
        </div>
        <div
            class="footer__main_right-rules"
            v-for="(item, index) in agree"
            :key="index"
        >
          <button class="footer__main_right-rules-link">
            <a :href="item.link" download="">{{ item.title }}</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name:'FooterVue',
  data() {
    return {
      agree: [],
      socials: [],
      emailLink: '',
      phoneLink: '',
      emailIcon: "",
      phoneIcon: "",
      contactRule: "",
    };
  },
  computed: {
    ...mapGetters(["getLang", "getAuth"]),
  },
  created() {
    this.$axios.get('contacts')
        .then(res => {
          this.agree = res.data.agreements;
          this.socials = res.data.socials;
          const contacts = res.data.contact;
          this.emailLink = contacts.email;
          this.emailIcon = contacts.email_icon;
          this.phoneLink = contacts.phone;
          this.phoneIcon = contacts.phone_icon;
          this.contactRule = contacts.rule;
        })
        .catch(err => {
          this.$toaster.error('Error')
        })
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $whiteText;
  padding: 115px 0;
  height: 100%;
  @media screen and (max-width: $tablet) {
    padding: 40px 0;
    height: 100%;
  }
  &__main {
    display: flex;
    justify-content: space-between;
    color: $mainTextColor;
    gap: 20px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
    &_left {
      display: flex;
      align-items: start;
      gap: 30px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }
      &-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        height: 100%;
        gap: 20px;
        &-img {
          max-width: 300px;
          max-height: 122px;
          img {
            width: 100%;
          }
        }
        &-subLogo {
          display: flex;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          &-img {
            width: 100%;
            max-height: 122px;
          }
        }
      }
      &-info {
        display: flex;
        flex-direction: column;
        max-width: 435px;
        gap: 10px;
        &-img {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          &-contacts {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          @media screen and (max-width: $tablet) {
            justify-content: center;
            align-items: center;
            gap: 20px;
          }
          img {
            width: 20px;
            height: 20px;
            @media screen and (max-width: $tablet) {
              width: 30px;
              height: 30px;
            }
          }
        }
        &-text {
          padding-top: 15px;
          @media screen and (max-width: $tablet) {
            padding: 25px;
          }
        }
      }
    }

    &_right {
      display: flex;
      flex-direction: column;
      gap: 50px;
      @media screen and (max-width: $tablet) {
        align-items: center;
      }
      &-socmedia {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        a {
          display: flex;
          align-items: center;
        }
        &-btn {
          button {
            border: 2px solid $mainTextColor;
            border-radius: 25px;
            padding: 12px 22px;
          }
        }
      }
      &-rules {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 38px;
        &:hover {
          text-decoration: underline;
        }
        @media screen and (max-width: $tablet) {
          align-items: center;
        }
      }
    }
  }
}
</style>
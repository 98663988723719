<template>
  <div class="notfound container">
    <div class="notfound__page">
      <img :src="require('@/assets/images/png/notfound.png')" alt="notfound" />
      <div class="notfound__page-content">
        <buttton
            @click="$router.push('/')"
            class="notfound__page-content-btn main-button"
        >
          {{ $t('buttons.onMainPage') }}
        </buttton>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  &__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      display: flex;
      justify-content: center;
      padding: 80px;
      height: 100vh;
      width: 100%;
    }
    &-content {
      padding-bottom: 155px;
      &-btn {
      }
    }
  }
}
</style>
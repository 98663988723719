<template>
  <div class="createacc">
    <div class="createacc__view">
      <div class="createacc__view-img">
        <img src="../../assets/images/png/login-image.png" alt="loginimg">
      </div>
      <div class="createacc__view-form blocks">
        <h1 class="createacc__view-form-title semi-bold-title">{{ $t('auth.creatAcc') }}</h1>
        <div class="createacc__view-form-inputs">
          <div class="createacc__view-form-inputs-required">
            <p class="main-title">{{ $t('auth.fullName') }}*</p>
            <input
                type="text"
                v-model="v$.fullname.$model"
                @keyup="onlyText()"
                :placeholder="$t('placeholder.fullName')"
                @keyup.enter="registerForm"
            />
            <template v-for="(error, index) of v$.fullname.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="createacc__view-form-inputs-required">
            <p class="main-title">{{ $t('auth.organization') }}*</p>
            <select
                class="createacc__view-form-inputs-required-select"
                v-model="organization"
            >
              <option value="" disabled> {{ $t('auth.selectOrganization') }} </option>
              <option
                  v-for="item in organizationsArray"
                  :key="item.id"
                  :value="item.id"
              >
                {{ item.title }}
              </option>
            </select>
            <template v-for="(error, index) of v$.organization.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="createacc__view-form-inputs-required">
            <p class="main-title">Email*</p>
            <input
                type="email"
                v-model="v$.email.$model"
                :placeholder="$t('placeholder.email')"
                @keyup.enter="registerForm"
            />
            <template v-for="(error, index) of v$.email.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="createacc__view-form-inputs-required">
            <p class="main-title">{{ $t('auth.password') }}*</p>
            <input
                type="password"
                v-model="v$.password.$model"
                :placeholder="$t('placeholder.password')"
                @keyup="onlyLatin()"
                @keyup.enter="registerForm"
            />
            <template v-if="v$.password.$error">
              <p class="errorValid" v-if="v$.password.required.$invalid" :key="index">{{$t('errors.required')}}</p>
              <p class="errorValid" v-if="v$.password.minLength.$invalid" :key="index">{{$t('errors.minLength', {value: 8})}}</p>
              <p class="errorValid" v-if="v$.password.containsUpperCase.$invalid" :key="index">{{$t('errors.containsUpperCase')}}</p>
              <p class="errorValid" v-if="v$.password.containsNumber.$invalid" :key="index">{{$t('errors.containsNumber')}}</p>
              <p class="errorValid" v-if="v$.password.noSpaces.$invalid" :key="index">{{$t('errors.noSpaces')}}</p>
            </template>
          </div>

          <div class="createacc__view-form-inputs-button">
            <button
                class="loginpage__view-form-inputs-button-enter main-button"
                @click="registerForm"
            >
              {{ $t('auth.creatAcc') }}
            </button>
          </div>

          <div class="createacc__view-form-inputs-login">
            <p class="regular-txt">{{ $t('auth.existAcc') }} <span @click="$router.push('/login')" >{{ $t('buttons.enter') }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  helpers
} from "@vuelidate/validators";
import { createToaster } from "@meforma/vue-toaster";
import {mapGetters} from "vuex";
import {accountService} from "@/_services";
import { router } from '@/_helpers';
const toaster = createToaster({ position: "top-right" });

export default {
  components: {
  },
  data() {
    return {
      v$: useVuelidate(),
      fullname: "",
      email: "",
      password: "",
      organization: "",
      organizationsArray: [],
    };
  },
  validations() {
    return {
      fullname: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage(
            this.$t('errors.minLength', {value: 3}),
            minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        email: helpers.withMessage((this.$t('errors.email')), email),
      },
      organization: {
        required: helpers.withMessage((this.$t('errors.required')), required),
      },
      password: {
        required: required,
        minLength: minLength(8),
        containsUpperCase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /\d/.test(value);
        },
        noSpaces(value) {
          return !/\s/.test(value);
        },
      },
    };
  },
  setup(props, context) {
    if (accountService.accountValue) {
      router.push('/');
    }
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {
    this.$axios.get(`organizations`, {
      params: {
        lang: localStorage.getItem('lang') || 'ru'
      },
    })
        .then(response => {
          this.organizationsArray = response.data.organizations;
        })
        .catch(error => {
          console.error(error);
        });
  },
  methods: {
    registerForm() {
      this.v$.$validate();
      if (
          !this.v$.$invalid
      ) {
        const data = {
          name: this.fullname,
          email: this.email,
          organization_id: this.organization,
          password: this.password,
        };
        this.$axios
            .post("registration", data)
            .then((response) => {
              localStorage.setItem("access_token", response.token);
              this.$router.push("successfully");
              this.resetForm()
              toaster.success(this.$t('toasters.successAuth'));
            })
            .catch((err) => {
              this.$toaster.error(err.response.data.message);
            });
      }
      else {
        this.$toaster.error(this.$t('toasters.fullInput'))
      }
    },
    resetForm() {
      this.fullname = "",
      this.email = "",
      this.password = "",
      this.v$.$reset()
    },
    onlyLatin() {
      this.password = this.password.replace(/[^a-zA-Z0-9\s.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
    },
    onlyText() {
      this.fullname = this.fullname.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.fullname = this.fullname.replace(/\.{2,}|\s{2,}|\-{2,}/g, function (match) {
        return match.substr(0, 1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.createacc__view {
  display: flex;
  justify-content: center;
  width: 100%;
  object-fit: cover;
  gap: 70px;
  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 60px 0;
    img{
      width: clamp(350px, calc(21.875rem + ((1vw - 10.24px) * 44.6429)), 750px);;
      height: clamp(500px, calc(31.25rem + ((1vw - 10.24px) * 45.6473)), 909px);;
    }
    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 125px 100px;
    width: 50%;
    @media screen and (max-width: $laptopL) {
      padding: 125px 50px;
    }
    @media screen and (max-width: $tablet) {
      width: 95%;
      margin: 30px 0;
      padding: 55px 40px;
      align-items: center;
    }
    h1 {
      font-size: 30px;
    }
    &-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 35px;
      padding: 50px 0;
      @media screen and (max-width: $laptopL) {
        width: 100%;
      }
      &-required {
        input,
        select {
          background: rgba(176, 186, 195, 0.4);
          width: 100%;
          border-radius: 20px;
          padding: 20px 30px 20px 30px;
          &:optional {
            color: #7C838A !important;
          }
        }
        p {
          color: #7C838A;
          font-size: 24px;
        }
      }
      &-button {
        display: flex;
        justify-content: center;
        button {
          margin-top: 32px;
          width: 340px;
        }
      }
      &-login {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #02AEF0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<template>
  <div class="lessonMenu blocks">
      <div class="lessonMenu__sideImg">
      <img
          src="../../assets/icons/side-menu.svg"
          alt="side-menu"
          @click.stop="openLessonsMenu"
      >
    </div>
    <div
        class="lessonMenu__content"
        :class="{ 'lessonMenu__content-open': isBlockDown }"
        v-for="module in modules"
        :key="module.id"
        v-if="isBlockDown === true"
    >
      <div
          class="lessonMenu__content-title"
          @click="toggleModuleLessons(module.id)"
      >
        <h2 class="main-title">{{ module.title }}</h2>
        <img
            src="../../assets/icons/down-arrow.svg"
            alt="down-arrow"
            :class="{ 'lessonMenu__content-title-up': module.isOpen }"
        >
      </div>
      <div v-if="module.isOpen">
        <div
            class="lessonMenu__content-navigation"
            v-if="module.lessons"
            v-for="(lesson, idx) in module.lessons"
            :key="idx"
            @click="changeLesson(lesson)"
        >
          <div class="lessonMenu__content-navigation-menu">
            <div class="lessonMenu__content-navigation-menu-img">
              <img src="../../assets/icons/mycourses.svg" alt="mycourses">
            </div>
            <p class="regular-txt">{{ lesson.title }}</p>
          </div>
        </div>
        <div
            class="lessonMenu__content-moduleTest"
            v-if="module.test && module.test.status === 1"
            @click="redirectToTest(module, module.test)"
        >
          <div class="lessonMenu__content-moduleTest-title">
            <img src="@/assets/icons/guidance_paper.svg" alt="guidance_paper">
            <p>
              {{ module.test.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modules: [],
      courseTitleMenu: '',
      isBlockDown: false,
      lastLessonTitle: null
    };
  },
  created() {
    const educationId = this.$route.params.educationId;
    this.fetchLessonData(educationId);
  },
  methods: {
    async fetchLessonData(educationId) {
      await this.$axios
          .get(`courses/${educationId}/modules`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            this.modules = response.data.modules.map((module) => {
              module.lessons = response.data.modules.find((m) => m.id === module.id)?.lessons || [];
              return module;
            });
            const lastAvailableLessonOrTest = this.findLastAvailableLessonOrTest();
            if (lastAvailableLessonOrTest) {
              this.lastLessonTitle = lastAvailableLessonOrTest;
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
    findLastAvailableLessonOrTest() {
      for (let i = this.modules.length - 1; i >= 0; i--) {
        const module = this.modules[i];

        if (module.test && module.test.status === 1) {
          return `${module.test.title} - ${module.title}`;
        }

        for (let j = module.lessons.length - 1; j >= 0; j--) {
          const lesson = module.lessons[j];
          if (lesson.status === 1) {
            return lesson.title;
          }
        }
      }
      return null;
    },
    toggleModuleLessons(moduleId) {
      const module = this.modules.find((m) => m.id === moduleId);
      if (module) {
        module.isOpen = !module.isOpen;
      }
    },
    openLessonsMenu() {
      return this.isBlockDown = !this.isBlockDown,
          this.openMarsh = false;
    },
    changeLesson(lesson) {
      const educationId = this.$route.params.educationId;
      if (lesson.status === 0) {
        this.$toaster.error(this.$t('errors.unavailableLesson') + ": " +this.lastLessonTitle);
      } else {
        this.$router.push({ name: 'education-lesson', params: { educationId: educationId, lessonId: lesson.id } });
        localStorage.setItem('currentStage', 'presentation');
        localStorage.setItem('currentPage', '1');
      }
    },
    redirectToTest(module, test) {
      const educationId = this.$route.params.educationId;
      if (module.lessons.status === 0) {
        this.$toaster.error(this.$t('errors.unavailableLesson') + ": " +this.lastLessonTitle);
      } else {
        this.$router.push({ name: 'education-test', params: { educationId: educationId, moduleId: module.id, testId: test.id } });
      }
    },
  },
  watch: {
    '$route.params': function () {
      const currentStage = localStorage.getItem('currentStage');
      if (currentStage === 'presentation') {
        this.$router.go();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lessonMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 20px 15px 20px 15px;
  position: relative;
  @media screen and (max-width: $tablet) {
    padding: 0;
    gap: 10px;
  }
  &__sideImg {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: $tablet) {
      padding: 15px 20px;
    }
    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 10px;
    @media screen and (max-width: $tablet) {
      padding: 0 10px;
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;
      padding: 20px;
      &:hover {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
      }
      h2 {
        font-size: 20px;
      }
      &-up {
        transform: rotate(180deg);
      }
    }
    &-navigation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px;
      cursor: pointer;
      gap: 10px;
      &-menu {
        display: flex;
        align-items: flex-start;
        gap: 20px;
      }
      img {
        width: 20px;
        height: 20px;
      }
      p {
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
      }

      &:hover {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
      }

      &.active {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
        justify-content: center;
      }
    }
    &-moduleTest {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px;
      cursor: pointer;
      gap: 10px;
      &-title {
        display: flex;
        align-items: flex-start;
        gap: 20px;
      }
      img {
        width: 20px;
        height: 20px;
      }
      p {
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
      }

      &:hover {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
      }

      &.active {
        background-color: rgba(114, 114, 126, 0.3);
        border-radius: 10px;
        justify-content: center;
      }
    }
  }
}
.blocks-active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
<template>
  <div class="notifications">
    <div class="notifications__content">
      <div class="notifications__content-title main-title blocks-title">
        <h1 class="notifications__content-title-txt">{{ $t('notifications') }}</h1>
        <div class="notifications__content-title-currpage">
          <p>{{ $t('profile') }} / {{ $t('notifications') }}</p>
        </div>
      </div>
      <div class="notifications__content-block blocks">
        <div
            v-if="allNotifications.length > 0"
            class="notifications__content-block-view"
        >
          <h1 class="main-title">{{ $t('allNotifications') }}</h1>
          <div
              v-for="(notification, index) in allNotifications"
              :key="index"
              class="notification-item"
          >
            <div class="notification-item-info">
              <h3 class="main-title">{{ notification.title }}</h3>
              <p class="regular-txt">{{ notification.description }}</p>
            </div>
            <div class="notification-item-date">
              <p class="light-txt">{{ formatNotificationDate(notification.created_at) }}</p>
            </div>
          </div>
        </div>
        <div v-else class="notification-empty">
          {{ $t('noNotifications') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  components: {
  },
  data() {
    return {
      allNotifications: [],
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    userNotifications() {
      this.$axios.get('/user-notifications')
          .then(response => {
            this.allNotifications = response.data.userNotifications;
          })
          .catch(error => {
            console.error(error);
          });
    },
    formatNotificationDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  created() {
    this.userNotifications();
  }
};
</script>

<style lang="scss" scoped>
.notifications {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-block {
      padding: 20px;
      &-view {
        display: flex;
        flex-direction: column;
        background: #F9F9F9;
        padding: 20px;
        border-radius: 10px;
        h1 {
          font-size: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 0.6684)), 30px);
          padding: 10px 0;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}
.notification-item {
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  @media screen and (max-width: $tablet) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h3 {
      font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.5348)), 24px);
    }
  }
  &-date {

  }
}
</style>
<template>
  <div class="home">
    <div
        class="home__banner"
        :style="{ backgroundImage: `url('${mainpage.image}')` }"
    >
      <div class="home__banner-title container">
        <h1 class="home__banner-title-txt bold-title">{{ mainpage.title }}</h1>
      </div>
    </div>
    <div class="home__content container">
      <div class="home__content_courses">
        <div class="home__content_courses-title main-title">
          <p>{{ $t('home.coursesTitle') }}</p>
        </div>
        <div class="home__content_courses-card">
          <div
              class="home__content_courses-card-item"
              v-for="course in homeCoursesList"
              :key="course.id"
          >
            <HomeCoursesCard
                class="home__content_courses-card-item-content"
                :course="course"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeCoursesCard from "@/components/CoursesCard.vue";
import {mapGetters} from "vuex";
export default {
  components: {
    HomeCoursesCard
  },
  data() {
    return {
      mainpage: {
        title: "",
        image: ""
      },
      homeCoursesList: []
    };
  },
  computed: {
    ...mapGetters(["getUser", "getAuth"]),
  },
  methods: {
  },
  async created() {
    await this.$axios
        .get(`page/home`)
        .then((response) => {
          this.mainpage = response.data.banner;
          this.homeCoursesList = response.data.courses
        })
        .catch((e) => {
          console.log(e);
        });
  },
};
</script>

<style lang="scss" scoped>
.home {
  color: $mainTextColor;
  &__banner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    width: 100%;
    @media screen and (max-width: $tablet) {
      height: 820px;
    }
    @media screen and (max-width: $mobile) {
      height: 680px;
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      &-txt {
        font-size: clamp(30px, calc(1.875rem + ((1vw - 4.24px) * 4.6791)), 100px);
        color: $whiteText;
        @media screen and (max-width: $tablet) {
          text-align: center;
        }
      }
    }
  }
  &__content {
    margin-top: 100px;
    &_courses {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-bottom: 240px;
      @media screen and (max-width: $tablet) {
        margin-bottom: 40px;
      }
      &-title {
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.9358)), 30px);
        margin-bottom: 40px;
        width: 100%;
        @media screen and (max-width: $tablet) {
          text-align: center;
        }
      }
      &-card {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
        &-item {
          flex-basis: calc(50% - 20px);
        }
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="edumain">
    <div class="edumain__content">
      <div
          class="edumain__content-title main-title blocks-title"
          v-if="$route.name === 'education'"
      >
        <h1 class="edumain__content-title-txt">{{ courseData.title  }}</h1>
        <div class="edumain__content-title-currpage current-page">
          <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
          <p>/</p>
          <p @click="$router.push('/personal/mycourses')">{{ $t('personal.myCourses') }}</p>
          <p>/</p>
          <p>{{ courseData.title }}</p>
        </div>
      </div>
      <div class="edumain__content_course blocks">
        <div class="edumain__content_course-intro">
          <p class="main-title">{{ $t('education.intro') }}</p>
          <button
              class="edumain__content_course-intro-btn watch-button"
              @click="goToIntro"
          >
            {{ $t('buttons.watch') }}
          </button>
        </div>

        <div class="edumain__content_course-block" v-if="modules && !isCourseUnavailable">
          <div
              class="edumain__content_course-block-lesson"
              v-for="module in modules"
              :key="module.id"
              :id="'module-' + module.title"
          >
            <h2 class="main-title">{{ module.title }}</h2>
            <div
                class="edumain__content_course-block-lesson-info"
                v-if="module.lessons"
                v-for="(lesson, idx) in module.lessons"
                :key="idx"
            >
              <div class="edumain__content_course-block-lesson-info-title">
                <img src="@/assets/icons/guidance_paper.svg" alt="guidance_paper">
                <p>
                  {{ lesson.title }}
                </p>
              </div>

              <div
                  class="edumain__content_course-block-lesson-info-btns"
                  v-if="$route.name === 'education' && lesson.status === 1"
              >
                <button
                    class="watch-button"
                    v-if="lesson.status === 1"
                    @click="redirectToLesson(lesson.id, module.id)"
                >
                  {{ $t('buttons.watch') }}
                </button>
                <a v-if="lesson.document" :href="lesson.document" download="">
                  <img
                      src="@/assets/icons/download_available.svg" alt="download_available"
                  >
                </a>
              </div>
              <div
                  class="edumain__content_course-block-lesson-info-limitBtns"
                  v-if="$route.name === 'education' && lesson.status === 0"
              >
                <button
                    class="limit-button"
                >
                  {{ $t('buttons.limit') }}
                </button>
                <p>{{ $t('errors.unavailableLesson') }}: {{ lastAvailableLessonId }}</p>
                <img
                    src="@/assets/icons/download_denied.svg" alt="download_denied"
                >
              </div>
            </div>
            <div class="edumain__content_course-block-lesson-noinfo" v-if="module.lessons == 0">
              <p>{{ $t('errors.noLessons') }}</p>
            </div>
            <div
                class="edumain__content_course-block-lesson-moduletest"
                v-if="module.test && module.test.status == 1"
            >
              <div class="edumain__content_course-block-lesson-moduletest-title">
                <img src="@/assets/icons/guidance_paper.svg" alt="guidance_paper">
                <p>
                  {{ module.test.title }}
                </p>
              </div>
              <button
                  class="watch-button"
                  @click="goToTestPage(module.test.id, module.id)"
                  v-if="$route.name === 'education'"
              >
                {{ $t('buttons.goToTest') }}
              </button>
            </div>
            <div
                class="edumain__content_course-block-lesson-modulePassed"
                v-if="module.test && module.test.score && module.test.score >= 70"
            >
              <p class="main-title">{{ $t('education.modulePassed') }}</p>
            </div>
            <div
                class="edumain__content_course-block-lesson-info-grades"
                v-if="$route.name === 'education-grades'"
            >
              <p class="main-title">{{ $t('tests.gradesTitle') }}</p>
              <p
                  v-if="module.test && module.test.score"
                  :style="{ color: getGradeColor(module.test.score) }"
              >
                {{ module.test.score }} {{ $t('tests.gradesScore') }}
              </p>
            </div>
          </div>
          <div
              class="edumain__content_course-block-testing"
              v-if="courseData.courseTest"
          >
            <h2 class="main-title">{{ $t('education.finalTesting') }}</h2>

            <div class="edumain__content_course-block-testing-info">
              <div class="edumain__content_course-block-testing-info-title">
                <img src="@/assets/icons/guidance_paper.svg" alt="guidance_paper">
                <p>
                  {{ courseData.courseTest.title }}
                </p>
              </div>

              <div class="edumain__content_course-block-testing-info-btns">
                <button
                    class="watch-button"
                    v-if="courseData.courseTest.status === 1"
                    @click="goTofinalTesting(courseData.courseTest.id)"
                >
                  {{ $t('buttons.goToTest') }}
                </button>
                <button
                    class="limit-button"
                    v-if="courseData.courseTest.status === 0"
                >
                  {{ $t('buttons.limit') }}
                </button>
                <template
                    v-if="courseData.courseTest.status === 0"
                    class="edumain__content_course-block-testing-info-btns-message"
                >
                  <p>{{ $t('errors.unavailableLesson') }}: {{ lastAvailableLessonId }}</p>
                </template>
              </div>
            </div>
          </div>
        </div>
        <Loader v-if="loading"/>
        <div v-if="!loading && isCourseUnavailable" class="edumain__content_course-notAvailableBlock">
          <h1 class="errorValid">{{ $t('errors.courseUnavailable') }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Loader from '../UI/Loader.vue';
export default {
  props: [],
  components: {
    Loader
  },
  data() {
    return {
      courseTitle: '',
      courseData: [],
      modules: [],
      loading: false,
      lastAvailableLessonId: null,
    };
  },
  computed: {
    areAllLessonsUnavailable() {
      for (const module of this.modules) {
        for (const lesson of module.lessons) {
          if (lesson.status !== 0) {
            return false; // Хотя бы один урок доступен
          }
        }
      }
      return true; // Все уроки недоступны
    },
    isCourseUnavailable() {
      return this.areAllLessonsUnavailable;
    },
  },
  methods: {
    redirectToLesson(lessonId) {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education-lesson', params: { educationId: educationId, lessonId: lessonId } });
    },
    goToTestPage(testId, moduleId) {
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education-test', params: { educationId: educationId, moduleId: moduleId, testId: testId } });
    },
    goTofinalTesting(finalTestId){
      const educationId = this.$route.params.educationId;
      this.$router.push({ name: 'education-final-test', params: { educationId: educationId, finalTestId: finalTestId } });
    },
    goToIntro() {
      this.$router.push({ name: 'education-intro', params: { educationId: this.courseData.id } });
    },
    findLastAvailableLessonOrTest() {
      for (let i = this.modules.length - 1; i >= 0; i--) {
        const module = this.modules[i];

        if (module.test && module.test.status === 1) {
          return `${module.test.title} - ${module.title}`;
        }

        for (let j = module.lessons.length - 1; j >= 0; j--) {
          const lesson = module.lessons[j];
          if (lesson.status === 1) {
            return lesson.title;
          }
        }
      }

      return null;
    },
    getGradeColor(score) {
      if (score >= 0 && score < 50) {
        return "#CE0000";
      } else if (score > 50 && score < 80) {
        return "#D3830B";
      } else if (score > 80 && score <= 100) {
        return "#01FF2A";
      } else {
        return "#062060";
      }
    },
    fetchCourseData(educationId) {
      this.loading = true;
      this.$axios
          .get(`courses/${educationId}/modules`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            this.courseData = response.data.course;
            this.modules = response.data.modules.map((module) => {
              module.lessons = response.data.modules.find((m) => m.id === module.id)?.lessons || [];
              return module;
            });
            const lastAvailableLessonOrTest = this.findLastAvailableLessonOrTest();
            if (lastAvailableLessonOrTest) {
              this.lastAvailableLessonId = lastAvailableLessonOrTest;
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false; // Завершение загрузки
          });
    },
  },
  created() {
    const educationId = this.$route.params.educationId;
    this.fetchCourseData(educationId);
  },
};
</script>

<style lang="scss" scoped>
.edumain {
  width: 100%;
  color: $mainTextColor;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_course {
      padding: 40px;
      &-intro {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid #BDBDBD;
        p {
          font-size: 24px;
        }
      }

      &-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 35px;
        &-lesson {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 35px;
          &-info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            &-title {
              display: flex;
              align-items: center;
              gap: 20px;
            }
            &-btns {
              display: flex;
              align-items: center;
              gap: 20px;
              p {
                color: #CE0000;
                @media screen and (max-width: $tablet) {
                  display: none;
                }
              }
            }
            &-limitBtns {
              display: flex;
              align-items: center;
              gap: 20px;
              p {
                color: #CE0000;
                @media screen and (max-width: $tablet) {
                  display: none;
                }
              }
            }
            &-grades {
              display: flex;
              align-items: center;
              gap: 20px;
            }
          }

          &-moduletest {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            &-title {
              display: flex;
              align-items: center;
              gap: 20px;
            }
          }
          &-modulePassed {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            p {
              color: #01FF2A;
            }
          }
        }

        &-testing {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 35px;
          &-info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            &-title {
              display: flex;
              align-items: center;
              gap: 20px;
            }
            &-btns {
              display: flex;
              align-items: center;
              gap: 20px;
              p {
                color: #CE0000;
                @media screen and (max-width: $tablet) {
                  display: none;
                }
              }
              &-message {
                p {
                  color: #CE0000;
                }
              }
            }
            @media screen and (max-width: $tablet) {
              align-items: flex-start;
              flex-direction: column;
            }
          }
        }
      }
      &-notAvailableBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 40px;
        justify-content: center;
        h1 {
          text-align: center;
          font-size: clamp(20px, calc(1.25rem + ((1vw - 4.24px) * 0.6684)), 30px) !important;
        }
      }
    }
  }
}
h2 {
  font-size: 30px;
  padding-top: 40px;
}
</style>
<template>
  <div class="loginpage">
    <div class="loginpage__view">
      <div class="loginpage__view-img">
        <img src="../../assets/images/png/login-image.png" alt="loginimg">
      </div>
      <div class="loginpage__view-form blocks">
        <h1 class="loginpage__view-form-title semi-bold-title">{{ $t('auth.login') }}</h1>
        <div class="loginpage__view-form-inputs">
          <div class="loginpage__view-form-inputs-required">
            <p class="main-title">Email*</p>
            <input
                type="email"
                v-model="v$.email.$model"
                :placeholder="$t('placeholder.email')"
                @keyup.enter="loginForm"
            />
            <template v-for="(error, index) of v$.email.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="loginpage__view-form-inputs-required">
            <p class="main-title">{{ $t('auth.password') }}</p>
            <input
                type="password"
                v-model="v$.password.$model"
                :placeholder="$t('placeholder.password')"
                @keyup.enter="loginForm"
            />
            <template v-if="v$.password.$error">
              <p class="errorValid" v-if="v$.password.required.$invalid" :key="index">{{$t('errors.required')}}</p>
<!--              <p class="errorValid" v-if="v$.password.minLength.$invalid" :key="index">{{$t('errors.minLength', {value: 8})}}</p>-->
<!--              <p class="errorValid" v-if="v$.password.containsUpperCase.$invalid" :key="index">{{$t('errors.containsUpperCase')}}</p>-->
<!--              <p class="errorValid" v-if="v$.password.containsNumber.$invalid" :key="index">{{$t('errors.containsNumber')}}</p>-->
              <p class="errorValid" v-if="v$.password.noSpaces.$invalid" :key="index">{{$t('errors.noSpaces')}}</p>
            </template>
          </div>

          <div class="loginpage__view-form-inputs-button">
            <button
                class="loginpage__view-form-inputs-button-enter main-button"
                @click="loginForm"
            >
              {{ $t('buttons.enter') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  helpers
} from "@vuelidate/validators";
import {mapActions} from "vuex";
import { router } from '@/_helpers';
import { accountService } from '@/_services';

export default {
  components: {
  },
  data: () => ({
    v$: useVuelidate(),
    email: "",
    password: "",
  }),
  validations() {
    return {
      email: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        email: helpers.withMessage((this.$t('errors.email')), email),
      },
      password: {
        required: required,
        // minLength: minLength(8),
        // containsUpperCase(value) {
        //   return /[A-Z]/.test(value);
        // },
        // containsNumber(value) {
        //   return /\d/.test(value);
        // },
        noSpaces(value) {
          return !/\s/.test(value);
        },
      },
    };
  },
  methods: {
    ...mapActions(["requestUser"]),
    loginForm() {
      this.v$.$validate();
      if (
          !this.v$.$invalid
      ) {
        const data = {
          email: this.email,
          password: this.password,
        };
        this.$axios.post("login", data)
            .then(res => {
              localStorage.setItem("access_token", res.data.token);
              this.$toaster.success(this.$t('toasters.successLogin'));
              this.resetForm()
              setTimeout(() => {
                this.requestUser();
                this.$router.push('/personal');
              }, 200);
            })
            .catch(err => {
              if(err.response.data.message) {
                this.$toaster.error(err.response.data.message);
              }
            })
      }
      else {
        this.$toaster.error(this.$t('toasters.fullInput'))
      }
    },

    resetForm() {
      this.email = "",
      this.password = "",
      this.v$.$reset()
    },
    // onlyLatin() {
    //   this.password = this.password.replace(/[^a-zA-Z0-9\s.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
    // },
    onlyText() {
      this.fullname = this.fullname.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.fullname = this.fullname.replace(/\.{2,}|\s{2,}|\-{2,}/g, function (match) {
        return match.substr(0, 1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginpage__view {
  display: flex;
  justify-content: center;
  width: 100%;
  object-fit: cover;
  gap: 70px;
  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 60px 0;
    img{
      width: clamp(350px, calc(21.875rem + ((1vw - 10.24px) * 44.6429)), 750px);;
      height: clamp(500px, calc(31.25rem + ((1vw - 10.24px) * 45.6473)), 909px);;
    }
    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 125px 100px;
    width: 50%;
    @media screen and (max-width: $laptopL) {
      padding: 125px 50px;
    }
    @media screen and (max-width: $tablet) {
      width: 95%;
      margin: 30px 0;
      padding: 55px 40px;
      align-items: center;
    }
    h1 {
      font-size: 30px;
    }
    &-inputs {
      display: flex;
      flex-direction: column;
      width: 90%;
      gap: 35px;
      padding: 50px 0;
      input {
        background: rgba(176, 186, 195, 0.4);
        width: 100%;
        border-radius: 20px;
        padding: 20px 30px 20px 30px;
      }
      &-required {
        p {
          color: #7C838A;
          font-size: 24px;
        }
      }
      &-button {
        display: flex;
        justify-content: center;
        button {
          margin-top: 32px;
          width: 340px;
        }
      }
      &-register {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #02AEF0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
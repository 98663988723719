<template>
  <div class="courseslist">
    <div class="courseslist__title blocks-title">
      <h1 class="courseslist__title-txt main-title">{{ $t('personal.listCourses') }}</h1>
      <div class="courseslist__title-currpage current-page">
        <p @click="$router.push('/personal')">{{ $t('profile') }}</p>
        <p>/</p>
        <p>{{ $t('personal.listCourses') }}</p>
      </div>
    </div>
    <div class="courseslist__content">
      <div class="courseslist__content_courses blocks">
        <div class="courseslist__content_courses-title main-title">
          <p>{{ $t('home.coursesTitle') }}</p>
        </div>
        <div class="courseslist__content_courses-cards">
          <div
              class="courseslist__content_courses-cards-item"
              v-for="course in coursesList"
              :key="course.id"
          >
            <ListCoursesCard
                class="courseslist__content_courses-cards-item-content"
                :course="course"
                :typeButtonCard="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListCoursesCard from "@/components/CoursesCard.vue";
import {mapActions} from "vuex";

export default {
  props: ['typeButtonCard'],
  components: {
    ListCoursesCard
  },
  data() {
    return {
      coursesList: [],
    };
  },
  methods: {
    ...mapActions(["logoutUser"]),
    logoutSystem() {
      this.logoutUser();
      this.$router.push("/login");
    },
  },
  created() {
    this.$axios.
    get(`courses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then(res => {
          this.coursesList = res.data.courses;
        })
        .catch(err => {
          this.$toaster.error(this.$t('toasters.sessionTimeout'));
          this.logoutSystem();
        });
  }
};
</script>

<style lang="scss" scoped>
.courseslist {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: $mainTextColor;
  width: 100%;
  &__content {
    &_courses {
      display: flex;
      flex-direction: column;
      padding: 40px 40px 230px 40px;
      margin-bottom: 150px;
      &-title {
        font-size: 30px;
      }
      &-cards {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 20px;
        &-item {
          flex-basis: calc(50% - 20px);
        }
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
</style>